import {DestroyRef, Injectable} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Action, State, StateContext} from '@ngxs/store';
import {OnAuthenticated} from '@shared/shared-module/stores/actions/app.state.action';
import {getOtherDepartment, isOtherUniversity} from '@shared/shared-module/utils/code-utils';
import {EMPTY, Observable, tap} from 'rxjs';
import {CodeDto} from '../../core/api/generated/msa-admin-query';
import {CodesRestService, FixedHashCodeDto} from '../../core/api/generated/msa-duty-service';
import {
  FetchDepartmentCodesForUniversity,
  FetchFixedHashCodes,
  FetchReasonTypeCodeList,
  FetchUniversityNameCodeList,
  ResetUniversityDepartments
} from '../actions/code-list.state.actions';
import {CodeListStateModel} from '../models/code-list.state.model';

@State<CodeListStateModel>({
  name: 'codeList',
  defaults: {
    reasonTypes: [],
    universityNames: [],
    universityDepartments: [],
    fixedHashCodes: []
  }
})
@Injectable()
export class CodeListState {
  constructor(
    private codesRestService: CodesRestService,
    private destroyRef: DestroyRef
  ) {}

  @Action(ResetUniversityDepartments)
  resetUniversityDepartments(ctx: StateContext<CodeListStateModel>): void {
    ctx.patchState({universityDepartments: []});
  }

  @Action(FetchReasonTypeCodeList)
  fetchReasonTypeCodeList(ctx: StateContext<CodeListStateModel>): Observable<CodeDto[]> {
    const {reasonTypes: existingReasonTypes} = ctx.getState();
    if (existingReasonTypes.length > 0) return EMPTY;

    return this.codesRestService.getShiftRequestReasonTypes().pipe(
      tap(reasonTypes => ctx.patchState({reasonTypes})),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(FetchUniversityNameCodeList)
  fetchUniversityNameCodeList(ctx: StateContext<CodeListStateModel>): Observable<CodeDto[]> {
    const {universityNames: existingUniversityNames} = ctx.getState();
    if (existingUniversityNames.length > 0) return EMPTY;

    return this.codesRestService.getUniversities().pipe(
      tap(universityNames => ctx.patchState({universityNames})),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(FetchDepartmentCodesForUniversity)
  fetchUniversityDepartmentCodeList(
    ctx: StateContext<CodeListStateModel>,
    {universityCode}: FetchDepartmentCodesForUniversity
  ): Observable<CodeDto[]> {
    if (isOtherUniversity(universityCode) || !universityCode) return EMPTY;

    return this.codesRestService.getDepartmentCodesOfUniversity(universityCode).pipe(
      tap(universityDepartments =>
        ctx.patchState({universityDepartments: [...(universityDepartments ?? []), getOtherDepartment()]})
      ),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  @Action(OnAuthenticated)
  @Action(FetchFixedHashCodes)
  fetchFixedHashCodes(ctx: StateContext<CodeListStateModel>): Observable<FixedHashCodeDto[]> {
    const {fixedHashCodes: existingFixedHashCodes} = ctx.getState();
    if (existingFixedHashCodes.length > 0) return EMPTY;

    return this.codesRestService.getFixedHashCodes().pipe(
      tap(fixedHashCodes => ctx.patchState({fixedHashCodes})),
      takeUntilDestroyed(this.destroyRef)
    );
  }
}
