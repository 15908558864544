<label class="msa-text tw-mb-12">{{ label() | safeTranslate }}</label>

<section class="tw-flex tw-flex-col" [formGroup]="form">
  <msa-checkbox
    class="tw-mb-12"
    id="publicTransport"
    [formControl]="this.form.controls.usingPublicTransport"
    (checked)="togglePublicTransport($event)"
    >{{ "i18n.leave.publicTransport" | safeTranslate }}</msa-checkbox
  >

  @if (form.controls.publicTransport) {
    <div class="tw-grid tw-grid-cols-2 tw-gap-12" [formGroup]="form.controls.publicTransport">
      <msa-time-picker
        [formControl]="form.controls.publicTransport.controls.departure"
        label="i18n.leave.reasonTime.travelDeparture"
      />
      <msa-time-picker
        [formControl]="form.controls.publicTransport.controls.arrival"
        label="i18n.leave.reasonTime.travelArrival"
      />
    </div>
  }

  <msa-checkbox
    class="tw-mb-12"
    id="privateVehicle"
    [formControl]="this.form.controls.usingPrivateVehicle"
    (checked)="togglePrivateVehicle($event)"
    >{{ "i18n.leave.privateVehicle" | safeTranslate }}</msa-checkbox
  >

  @if (form.controls.privateVehicle) {
    <div class="tw-flex tw-w-full tw-flex-col">
      <label class="msa-text tw-mb-6" [appMarkRequired]="form.controls.privateVehicle">{{
        "i18n.leave.reasonTime.travelDuration" | safeTranslate
      }}</label>

      <div class="tw-grid tw-grid-cols-2 tw-gap-12 d:tw-grid-cols-4">
        <mat-form-field subscriptSizing="dynamic">
          <input
            class="text-end"
            [formControl]="form.controls.privateVehicle.controls.hours"
            type="number"
            matInput
            name="value"
            step="1"
            autocomplete="off"
            data-cy="hours"
          />
          <span class="tw-ps-6" matTextSuffix="">h</span>
        </mat-form-field>

        <mat-form-field subscriptSizing="dynamic">
          <input
            class="text-end"
            [formControl]="form.controls.privateVehicle.controls.minutes"
            type="number"
            matInput
            name="value"
            step="5"
            max="59"
            autocomplete="off"
            data-cy="minutes"
          />
          <span class="tw-ps-6" matTextSuffix="">m</span>
        </mat-form-field>

        <div class="tw-col-span-2 tw-hidden d:tw-block"></div>
      </div>
    </div>
  }
</section>
