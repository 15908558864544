import {HttpEventType} from '@angular/common/http';
import {DestroyRef, EventEmitter, Injectable} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Actions, Store, ofActionDispatched} from '@ngxs/store';
import {FileUploadHandler, UploadError} from '@shared/shared-module/services/file-upload/file-upload.service';
import {isDefined} from '@shared/shared-module/utils/is-defined';
import {BehaviorSubject, Observable, catchError, takeUntil, tap, throwError} from 'rxjs';
import {
  DocumentDto,
  DocumentDtoTypeEnum,
  DocumentsRestService,
  ErrorCode,
  ErrorMessageDto
} from '../core/api/generated/msa-admin-query';
import {SaveDraft, UpdateRequestDocuments} from '../stores/actions/edit-request.state.actions';
import {EditRequestStateSelectors} from '../stores/selectors/edit-request-state.selectors';

/**
 * Service specific for uploading request documents. We don´t want to move this
 * to the state store, since the state is local and only needed inside the context
 * of the component that uses the file upload component.
 */
@Injectable()
export class RequestDocumentUploadService implements FileUploadHandler<DocumentDto> {
  hasPendingUploads$ = new BehaviorSubject(false);

  private cancelUpload$ = new EventEmitter();

  constructor(
    private store: Store,
    private actions$: Actions,
    private documentsRestService: DocumentsRestService,
    private destroyRef: DestroyRef
  ) {}

  handleUploadError(error: any, file: File): UploadError[] {
    const filename = file.name;

    const errorMessageDtos = error.error as ErrorMessageDto[];
    return errorMessageDtos
      .map<UploadError>(errorMessage => {
        switch (errorMessage.errorCode) {
          case ErrorCode.FileSizeTooBig: {
            const size = errorMessage.properties?.['MAX_FILE_SIZE_MB'];
            return {error: {text: 'i18n.document.error.document-content-size-too-big', params: {size, filename}}};
          }
          case ErrorCode.MaxDocumentSizeReached: {
            const maxNbOfDocuments = errorMessage.properties?.['MAX_NUMBER_OF_DOCUMENTS'];
            return {
              error: {text: 'i18n.document.error.max-document-size-reached', params: {maxNbOfDocuments, filename}}
            };
          }
          case ErrorCode.CorruptedDocument:
            return {error: {text: 'i18n.document.error.document-corrupted', params: {filename}}};
          case ErrorCode.MaxNumberOfDocumentsExceeded:
            return {error: {text: 'i18n.document.error.maxNumberOfDocumentsExceeded', params: {filename}}};
          case ErrorCode.DocumentTypeNotSupported: {
            const allowedFormat = errorMessage.properties?.['ALLOWED_FORMAT'];
            return {
              error: {text: 'i18n.document.error.document-type-not-supported', params: {allowedFormat, filename}}
            };
          }
          default:
            return {error: {text: 'i18n.common.error.generic', params: {filename}}};
        }
      })
      .filter(isDefined);
  }

  upload$(file: File, s3BucketType: 'PISA' | 'MILO') {
    const request = this.store.selectSnapshot(EditRequestStateSelectors.getCurrentEditingRequest)!;

    this.hasPendingUploads$.next(true);
    return this.documentsRestService
      .createNewDocument(request.id, DocumentDtoTypeEnum.Other, s3BucketType, file, 'events', true)
      .pipe(
        tap(response => {
          if (response.type === HttpEventType.Response) {
            this.hasPendingUploads$.next(false);
            if (response.body) {
              const updatedDocuments = [...request.requestDetail.documents, response.body];
              this.store.dispatch(new UpdateRequestDocuments(updatedDocuments));
            }
          }
        }),
        catchError(err => {
          this.hasPendingUploads$.next(false);
          return throwError(() => err);
        }),
        takeUntil(this.cancelUpload$),
        takeUntilDestroyed(this.destroyRef)
      );
  }

  cancelUpload() {
    this.cancelUpload$.emit();
  }

  getResetStateTrigger(): Observable<any> {
    return this.actions$.pipe(ofActionDispatched(SaveDraft));
  }
}
