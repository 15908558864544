<msa-request-incomplete-notice />

@if (request().requestType !== RequestType.Reconsideration) {
  <app-duty-context />
}

<form [formGroup]="form" data-cy="privilegeForm">
  <label class="msa-text-field-set tw-mb-12" safeTranslate="i18n.leave.privileges.question"></label>

  <div class="tw-flex tw-flex-col tw-gap-12">
    <msa-checkbox id="privateVehicle" formControlName="privateVehicle">{{
      "i18n.leave.privileges.privateVehicle" | safeTranslate
    }}</msa-checkbox>
    <msa-checkbox id="civilClothes" formControlName="civilClothes">{{
      "i18n.leave.privileges.civilClothes" | safeTranslate
    }}</msa-checkbox>
  </div>
</form>

<div class="tw-mt-30 d:tw-mt-60"></div>

<app-form-stepper-navigation [preventNext]="form.invalid" (next)="onNext()" (previous)="back.emit()" />

<msa-save-draft [showSaveDraft]="hasUnsavedChanges()" (saveDraft)="onSaveClick()" />

<app-required-fields-notice />
