<msa-request-incomplete-notice />

@if (request().requestType !== RequestType.Reconsideration) {
  <app-duty-context />
} @else {
  <msa-request-context [requestId]="request().id" [dutyId]="request().dutyId" />
}

<h2 class="msa-text-field-set tw-mb-15 d:tw-mb-30">
  {{ "i18n.duty-relocation-form.gad.replacement-period.title" | safeTranslate }} *
</h2>

<form class="tw-mb-30 d:tw-mb-60" [formGroup]="form">
  <div class="tw-flex tw-gap-6 d:tw-gap-12">
    <label
      class="msa-text tw-flex-grow d:tw-flex-grow-0"
      safeTranslate="i18n.duty-relocation-form.gad.replacement-period.questionAtOnce"
    ></label>

    <msa-tooltip
      class="tw-flex-grow-0 d:tw-flex-grow-0"
      tooltipTitle="i18n.duty-relocation-form.gad.replacement-period.fractionOverlayTitle"
      tooltipContent="i18n.duty-relocation-form.gad.replacement-period.fractionOverlayMessage"
    />
  </div>

  <fieldset class="tw-flex tw-flex-col tw-gap-12 d:tw-flex-row" aria-label="Select an option">
    <app-msa-radiobutton id="atOnce" [value]="true" formControlName="atOnce" name="options"
      >{{ "i18n.duty-relocation-form.gad.replacement-period.optionDoAtOnce" | safeTranslate }}
    </app-msa-radiobutton>
    <app-msa-radiobutton id="fraction" [value]="false" formControlName="atOnce" name="options"
      >{{ "i18n.duty-relocation-form.gad.replacement-period.optionDoFraction" | safeTranslate }}
    </app-msa-radiobutton>
  </fieldset>

  @if (!form.value.atOnce && isDutyTypeDurchdiener()) {
    <div class="tw-mb-15 d:tw-mb-12 d:tw-mt-30">
      <app-msa-content-notice [type]="message.Warning">
        {{
          "i18n.duty-relocation-form.gad.replacement-period.longServantWarning" | safeTranslate
        }}</app-msa-content-notice
      >
    </div>
  }

  <label
    class="msa-text-field-set tw-mb-12 tw-mt-15 tw-inline-block d:tw-mt-30"
    safeTranslate="i18n.duty-relocation-form.gad.replacement-period.questionWhen"
  ></label>

  @if (form.value.atOnce) {
    <div class="fraction-period period-at-once">
      <mat-form-field class="tw-w-full">
        <mat-select formControlName="period1" required>
          @for (schedule of getDateRange(); track schedule) {
            <mat-option class="tw-flew-row tw-flex" [value]="schedule">
              {{ getSeasonTranslation(schedule["season"]) | safeTranslate }} {{ schedule["year"] }}
              <span class="tw-text-black-light-5">
                {{ schedule.startDate | date: GERMAN_SHORT_DATE_FORMAT }} -
                {{ schedule.endDate | date: GERMAN_SHORT_DATE_FORMAT }}
              </span>
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <div class="tw-mt-12 d:tw-mt-9">
        <app-msa-content-notice [type]="message.Info" data-cy="infoRekrutenschuleVerschiebung">
          {{ "i18n.duty-relocation-form.gad.replacement-period.maxPostpone" | safeTranslate }}
        </app-msa-content-notice>
      </div>
    </div>
  } @else {
    <div>
      <div class="msa-text tw-hidden tw-gap-12 tw-pb-9 tw-text-black-light-5 d:tw-grid d:tw-grid-cols-10">
        <div class="tw-col-span-1"></div>
        <div class="d:tw-col-span-3">
          {{ "i18n.duty-relocation-form.gad.replacement-period.rsShort" | safeTranslate }}
        </div>
        <div class="d:tw-col-span-3">{{ "i18n.common.weeks" | safeTranslate }}</div>
        <div class="d:tw-col-span-3">
          {{ "i18n.duty-relocation-form.gad.replacement-period.fromTo" | safeTranslate }}
        </div>
      </div>

      <!-- PERIOD 1 -->
      <div
        class="msa-text tw-col-span-10 tw-grid tw-grid-cols-1 tw-gap-9 tw-border-b tw-border-t tw-border-b-black-light-7 tw-border-t-black-light-7 tw-pb-18 tw-pt-18 d:tw-grid-cols-10 d:tw-gap-12"
      >
        <div class="tw-col-span-1 tw-content-center tw-font-bold d:tw-font-normal">
          {{ "i18n.duty-relocation-form.gad.replacement-period.1stPart" | safeTranslate }}
        </div>
        <div class="d:tw-col-span-3 d:tw-hidden">
          {{ "i18n.duty-relocation-form.gad.replacement-period.rsShort" | safeTranslate }}
        </div>
        <div class="d:tw-col-span-3">
          <mat-form-field class="tw-w-full" subscriptSizing="dynamic">
            <mat-select formControlName="period1" required>
              @for (schedule of getPeriod1DateRange(); track schedule) {
                <mat-option [value]="schedule" (click)="onSeason1Selected()"
                  >{{ getSeasonTranslation(schedule["season"]) | safeTranslate }} {{ schedule["year"] }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="d:tw-col-span-3 d:tw-hidden">{{ "i18n.common.weeks" | safeTranslate }}</div>
        <div class="d:tw-col-span-3">
          <mat-form-field class="tw-w-full" subscriptSizing="dynamic">
            <mat-select formControlName="weeksTillFraction" required>
              @for (week of weeksTillFraction; track week) {
                <mat-option [value]="week">{{ "i18n.common.weeks" | safeTranslate }} 1 - {{ week }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        @if (form.value.period1?.startDate) {
          <div class="color-secondary tw-text-black-light-5 d:tw-col-span-3 d:tw-hidden">
            {{ "i18n.duty-relocation-form.gad.replacement-period.fromTo" | safeTranslate }}
          </div>
          <div class="tw-content-center d:tw-col-span-3">
            {{ form.value.period1?.startDate | date: GERMAN_SHORT_DATE_FORMAT }} -
            {{ season1CalculatedEndDate() | date: GERMAN_SHORT_DATE_FORMAT }}
          </div>
        }
      </div>

      <!-- PERIOD 2 -->
      <div
        class="msa-text tw-col-span-10 tw-grid tw-grid-cols-1 tw-gap-9 tw-border-b tw-border-b-black-light-7 tw-pb-18 tw-pt-18 d:tw-grid-cols-10 d:tw-gap-12"
      >
        <div class="tw-col-span-1 tw-content-center tw-font-bold d:tw-font-normal">
          {{ "i18n.duty-relocation-form.gad.replacement-period.2ndPart" | safeTranslate }}
        </div>
        <div class="tw-col-span-1 d:tw-col-span-3 d:tw-hidden">
          {{ "i18n.duty-relocation-form.gad.replacement-period.rsShort" | safeTranslate }}
        </div>
        <div class="tw-col-span-1 d:tw-col-span-3">
          <mat-form-field class="tw-w-full">
            <mat-select formControlName="period2" required>
              @for (schedule of getPeriod2DateRange(); track schedule) {
                <mat-option [value]="schedule"
                  >{{ getSeasonTranslation(schedule["season"]) | safeTranslate }} {{ schedule["year"] }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>

        <div class="tw-col-span-1 d:tw-col-span-3 d:tw-hidden">{{ "i18n.common.weeks" | safeTranslate }}</div>
        <div class="tw-col-span-1 d:tw-col-span-3">
          @if (form.value.period2) {
            <mat-form-field class="tw-w-full">
              <mat-select value="weeksTillEnd" readonly disabled="true">
                <mat-option value="weeksTillEnd">{{ weeksFractionTillEnd() }}</mat-option>
              </mat-select>
            </mat-form-field>
          }
        </div>

        @if (form.value.period2) {
          <div class="tw-col-span-1 tw-text-black-light-5 d:tw-col-span-3 d:tw-hidden">
            {{ "i18n.duty-relocation-form.gad.replacement-period.fromTo" | safeTranslate }}
          </div>
          <div class="tw-col-span-1 tw-content-center d:tw-col-span-3">
            {{ season2CalculatedStartDate() | date: GERMAN_SHORT_DATE_FORMAT }} -
            {{ form.value.period2.endDate | date: GERMAN_SHORT_DATE_FORMAT }}
          </div>
        }
      </div>
    </div>

    <div class="tw-mt-12 d:tw-mt-9">
      <app-msa-content-notice [type]="message.Info">
        {{
          "i18n.duty-relocation-form.gad.replacement-period.catchUpForLearningSuccess" | safeTranslate
        }}</app-msa-content-notice
      >
    </div>
  }

  @if (isOlderThan(22)) {
    <div class="tw-mt-12 d:tw-mt-9">
      <app-msa-content-notice [type]="message.Info" data-cy="infoRekrutenschule25Abschliessen">{{
        "i18n.duty-relocation-form.gad.replacement-period.ageLimit" | safeTranslate
      }}</app-msa-content-notice>
    </div>
  }
</form>

<app-form-stepper-navigation [preventNext]="form.invalid" (next)="onNext()" (previous)="back.emit()" />

<msa-save-draft [showSaveDraft]="hasUnsavedChanges()" (saveDraft)="onSaveClick()" />

<app-required-fields-notice />
