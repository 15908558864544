import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, effect, inject, OnInit} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {RouterLink} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {KeyValueComponent} from '@shared/shared-module/components/key-value/key-value.component';
import {MsaButtonComponent} from '@shared/shared-module/components/msa-button/msa-button.component';
import {StatusPillComponent} from '@shared/shared-module/components/status-pill/status-pill.component';
import {TranslateObjectPipe} from '@shared/shared-module/pipes/translate-object.pipe';
import {SafeTranslateModule} from '@shared/shared-module/safe-translate.module';
import {readStoreSignal} from '@shared/shared-module/utils/store.utils';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {DutyContextComponent} from '../../../components/duty-context/duty-context.component';
import {RequestContextComponent} from '../../../components/request-context/request-context.component';
import {
  Action,
  DetailsShiftFdtUniversityDto,
  DetailsShiftGadUniversityDto,
  RequestType
} from '../../../core/api/generated/msa-admin-query';
import {
  FetchDepartmentCodesForUniversity,
  FetchReasonTypeCodeList,
  FetchUniversityNameCodeList
} from '../../../stores/actions/code-list.state.actions';
import {CreateReconsideration} from '../../../stores/actions/edit-request.state.actions';
import {EditRequestStateSelectors} from '../../../stores/selectors/edit-request-state.selectors';
import {RequestTranslationsUtils} from '../../../utils/translation.utils';
import {RequestSummaryComponent} from '../request-summary/request-summary.component';

/**
 * This component is only needed to provide the correct list
 * of steps to be displayed.
 */
@Component({
  selector: 'msa-shift-request-overview',
  templateUrl: './shift-request-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    DutyContextComponent,
    MatIconModule,
    RouterLink,
    TranslateObjectPipe,
    StatusPillComponent,
    SafeTranslateModule,
    KeyValueComponent,
    RequestSummaryComponent,
    RequestContextComponent,
    MsaButtonComponent
  ]
})
export class ShiftRequestOverviewComponent implements OnInit {
  private store = inject(Store);

  public readonly requestTrackingState = readStoreSignal(EditRequestStateSelectors.getTrackingState);

  public requestSnapshot = this.store.selectSnapshot(EditRequestStateSelectors.getCurrentEditingRequest)!;
  public readonly requestState = RequestTranslationsUtils.getStatusFields(this.requestSnapshot?.state);
  readonly isUniversityRequest = computed(() =>
    EditRequestStateSelectors.isUniversityDetail(this.requestSnapshot!.requestDetail)
  );

  protected readonly canOpenReconsideration = this.requestSnapshot
    ? this.requestSnapshot.actions.includes(Action.OpenReconsiderationRequest)
    : false;
  protected readonly requestTitle = computed<TranslationString>(() => {
    if (this.requestSnapshot ? this.requestSnapshot.requestType === RequestType.Reconsideration : false) {
      return 'i18n.duty-type.form.header-description.relocation';
    } else {
      return 'i18n.duty-relocation-form.examination.label';
    }
  });
  public readonly RequestType = RequestType;

  private loadUniCodeLists = effect(() => {
    if (!this.isUniversityRequest()) return;
    const universityRequest = this.requestSnapshot.requestDetail as
      | DetailsShiftFdtUniversityDto
      | DetailsShiftGadUniversityDto;

    this.store.dispatch(new FetchUniversityNameCodeList());
    this.store.dispatch(new FetchDepartmentCodesForUniversity(universityRequest.university.university));
  });

  ngOnInit() {
    this.store.dispatch(new FetchReasonTypeCodeList());
  }

  createReconsideration() {
    this.store.dispatch(new CreateReconsideration(this.requestSnapshot.id));
  }
}
