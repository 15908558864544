<div class="tw-pb-60 tw-pt-60 d:tw-pb-120 d:tw-pt-120" data-cy="requestOverview">
  <div class="tw-absolute tw-right-[3px] tw-top-[3px]">
    <mat-icon
      class="!tw-size-48 tw-text-black-light-7"
      routerLink="/admin-query/requests"
      data-cy="close"
      svgIcon="close"
    />
  </div>

  <h1 class="msa-text-h1 tw-mb-30 d:tw-mb-60" [translate]="requestTitle"></h1>

  <app-key-value key="i18n.leave.overview.submittedAt">
    <p>{{ requestSnapshot.submittedStateDateTime | date }}</p>
  </app-key-value>

  @if (requestState) {
    <app-key-value key="i18n.requests.headers.state">
      <div class="tw-flex tw-items-center">
        <msa-status-pill
          class="tw-flex-grow-0"
          [text]="requestState.statusText"
          [backgroundClass]="requestState.backgroundColor"
        />
        @if (divergentAcceptedPeriod()) {
          &nbsp;
          <small>
            <p>{{ "i18n.leave.overview.withChanges" | safeTranslate }}</p>
          </small>
        }
      </div>
    </app-key-value>
    <div class="tw-mt-30 d:tw-mt-60"></div>
  }

  @if (notificationText(); as notificationText) {
    <app-msa-content-notice type="Info">
      <p [innerHTML]="notificationText | safeTranslate"></p>
      <small>
        {{
          "i18n.leave.overview.infoNotificationBy"
            | safeTranslate
              : {
                  person: requestSnapshot.responseData.decisionMaker ?? "",
                  date: (requestSnapshot.responseData.decisionDateTime! | date) ?? "",
                }
        }}
      </small>
      <p>{{ requestSnapshot.responseData.responseReason! }}</p>
    </app-msa-content-notice>
    <div class="tw-mt-30 d:tw-mt-60"></div>
  }

  @if (showLeavePassInfo()) {
    <app-msa-content-notice type="Info" data-cy="infoLeavePass">
      <p [innerHTML]="'i18n.leave.overview.infoLeavePass' | safeTranslate"></p>
    </app-msa-content-notice>
    <div class="tw-mt-30 d:tw-mt-60"></div>
  }

  @if (requestSnapshot) {
    <msa-request-summary [requestId]="requestSnapshot.id" [showDutyContext]="true">
      <app-duty-context data-cy="affectedDuty" />
    </msa-request-summary>
  }

  <div class="tw-mt-30 tw-grid tw-grid-cols-1 tw-gap-15 d:tw-mt-60 d:tw-grid-cols-2 d:tw-gap-30">
    <div class="tw-order-2 d:tw-order-1">
      @if (canWithdrawRequest()) {
        <msa-button (click)="withdrawRequest()" data-cy="onConfirm" color="secondary"
          >{{ "i18n.requests.actions.withdraw" | safeTranslate }}
        </msa-button>
      }
    </div>

    <div class="tw-order-1 d:tw-order-2">
      <msa-button color="primary" routerLink="/admin-query/requests"
        >{{ "i18n.common.close" | safeTranslate }}
      </msa-button>
    </div>
  </div>
</div>
