<div class="timepicker-dial">
    <div class="timepicker-dial__container">
        <div class="timepicker-dial__time">
            <ngx-material-timepicker-dial-control [timeList]="hours" [time]="hour" [timeUnit]="timeUnit.HOUR"
                                                  [isActive]="activeTimeUnit === timeUnit.HOUR"
                                                  [isEditable]="isEditable"
                                                  (timeUnitChanged)="changeTimeUnit($event)"
                                                  (timeChanged)="changeHour($event)"
                                                  (focused)="showHint()"
                                                  (unfocused)="hideHint()">

            </ngx-material-timepicker-dial-control>
            <span>:</span>
            <ngx-material-timepicker-dial-control [timeList]="minutes" [time]="minute" [timeUnit]="timeUnit.MINUTE"
                                                  [isActive]="activeTimeUnit === timeUnit.MINUTE"
                                                  [isEditable]="isEditable"
                                                  [minutesGap]="minutesGap"
                                                  [disabled]="hoursOnly"
                                                  (timeUnitChanged)="changeTimeUnit($event)"
                                                  (timeChanged)="changeMinute($event)"
                                                  (focused)="showHint()"
                                                  (unfocused)="hideHint()">

            </ngx-material-timepicker-dial-control>
        </div>
        <ngx-material-timepicker-period class="timepicker-dial__period"
                                        [ngClass]="{'timepicker-dial__period--hidden': format === 24}"
                                        [selectedPeriod]="period" [activeTimeUnit]="activeTimeUnit"
                                        [maxTime]="maxTime" [minTime]="minTime" [format]="format"
                                        [hours]="hours" [minutes]="minutes" [selectedHour]="hour"
                                        [meridiems]="meridiems"
                                        (periodChanged)="changePeriod($event)"></ngx-material-timepicker-period>
    </div>
    <div *ngIf="isEditable || editableHintTmpl" [ngClass]="{'timepicker-dial__hint-container--hidden': !isHintVisible}">
        <!--suppress HtmlUnknownAttribute -->
        <ng-container *ngTemplateOutlet="editableHintTmpl ? editableHintTmpl : editableHintDefault"></ng-container>
        <ng-template #editableHintDefault>
            <small class="timepicker-dial__hint"> * use arrows (<span>&#8645;</span>) to change the time</small>
        </ng-template>
    </div>
</div>
