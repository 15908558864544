import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, DestroyRef, inject, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {TranslateModule} from '@ngx-translate/core';
import {FormStepperNavigationComponent} from '@shared/shared-module/components/form-stepper-navigation/form-stepper-navigation.component';
import {MsaDatePickerComponent} from '@shared/shared-module/components/msa-date-picker/msa-date-picker.component';
import {MsaFileUploadComponent} from '@shared/shared-module/components/msa-file-upload/msa-file-upload.component';
import {MsaSaveDraftComponent} from '@shared/shared-module/components/msa-save-draft/msa-save-draft.component';
import {MsaTimePickerComponent} from '@shared/shared-module/components/msa-time-picker/msa-time-picker.component';
import {RequiredFieldsNoticeComponent} from '@shared/shared-module/components/required-fields-notice/required-fields-notice.component';
import {MarkRequiredDirective} from '@shared/shared-module/directives/mark-required.directive';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {combineDateAndTime} from '@shared/shared-module/utils/date-time.utils';
import {isDefined} from '@shared/shared-module/utils/is-defined';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {pickBy} from 'lodash';
import moment, {Moment} from 'moment';
import {REASON_LEAVE_TEXTS} from 'projects/admin-query/src/app/models/leave/reason-types.model';
import {distinctUntilChanged, tap} from 'rxjs';
import {MsaTextInputComponent} from '../../../../../../../../src/app/shared-module/components/msa-text-input/msa-text-input.component';
import {DutyContextComponent} from '../../../../components/duty-context/duty-context.component';
import {RequestContextComponent} from '../../../../components/request-context/request-context.component';
import {
  DetailsLeaveDto,
  ReasonTypeLeave,
  TransportationType,
  TransportDetailsDto,
  TransportDto
} from '../../../../core/api/generated/msa-admin-query';
import {RequestDocumentUploadService} from '../../../../services/request-document-upload.service';
import {RequestIncompleteNoticeComponent} from '../../general/request-incomplete-notice/request-incomplete-notice.component';
import {StepEditRequestComponent} from '../../general/step-edit-request-component/step-edit-request.component';
import {LeaveReasonTimeFormGroup} from './leave-reason-time.form-group';
import {LeaveTransportComponent, TransportDetails} from './leave-transport/leave-transport.component';

type LeaveReasonOption = {
  code: ReasonTypeLeave;
  text: TranslationString;
};

@Component({
  selector: 'msa-step-leave-reason-time',
  templateUrl: './step-leave-and-transport-details.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RequestDocumentUploadService],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    FormStepperNavigationComponent,
    DutyContextComponent,
    MarkRequiredDirective,
    RequiredFieldsNoticeComponent,
    MsaFileUploadComponent,
    RequestIncompleteNoticeComponent,
    MsaSaveDraftComponent,
    RequestContextComponent,
    SafeTranslateDirective,
    MsaTimePickerComponent,
    MsaDatePickerComponent,
    SafeTranslatePipe,
    LeaveTransportComponent,
    MsaTextInputComponent
  ]
})
export class StepLeaveAndTransportDetailsComponent extends StepEditRequestComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  private dutyId = computed(() => this.request().dutyId);
  form = new LeaveReasonTimeFormGroup(this.dutyId);

  public readonly options: LeaveReasonOption[] = Object.values(ReasonTypeLeave).map(v => ({
    code: v,
    text: REASON_LEAVE_TEXTS[v]
  }));

  ngOnInit(): void {
    const leaveRequstDetail = this.request().requestDetail as DetailsLeaveDto;
    const transportDetails = leaveRequstDetail.transportDetails;

    this.form.patchValue({
      reason: transportDetails?.reasonType,
      fromDate: transportDetails?.leaveFrom ? moment.utc(transportDetails?.leaveFrom) : null,
      fromTime: transportDetails?.leaveFrom ? moment.utc(transportDetails?.leaveFrom).format('HH:mm') : '',
      toDate: transportDetails?.leaveTo ? moment.utc(transportDetails?.leaveTo) : null,
      toTime: transportDetails?.leaveTo ? moment.utc(transportDetails?.leaveTo).format('HH:mm') : '',
      travelOutbound: transportDetails?.travelOutbound
        ? this.mapTransportDtoToDetails(transportDetails.travelOutbound)
        : null,
      travelReturn: transportDetails?.travelReturn
        ? this.mapTransportDtoToDetails(transportDetails.travelReturn)
        : null,
      destination: transportDetails?.destination
    });

    this.form.valueChanges
      .pipe(
        distinctUntilChanged(),
        tap(() =>
          this.action.emit({
            type: 'updateUserFormData',
            payload: {transportDetails: this.getUpdateTransportDetailsDto()}
          })
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  onNext(): void {
    this.action.emit({type: 'updateTransportDetailsDto', payload: this.getUpdateTransportDetailsDto()});
  }

  onSaveClick() {
    this.action.emit({type: 'saveDraft'});
  }

  getUpdateTransportDetailsDto(): TransportDetailsDto {
    const formValues = this.form.value;
    return {
      reasonType: formValues.reason!,
      destination: formValues.destination!,
      leaveFrom: this.combineDateTimeOrInvalid(formValues.fromDate, formValues.fromTime).toISOString(),
      leaveTo: this.combineDateTimeOrInvalid(formValues.toDate, formValues.toTime).toISOString(),
      travelOutbound: formValues.travelOutbound ? this.getTransportDto(formValues.travelOutbound) : null,
      travelReturn: formValues.travelReturn ? this.getTransportDto(formValues.travelReturn) : null
    };
  }

  private combineDateTimeOrInvalid(date: Moment | undefined | null, time: string | undefined | null): Moment {
    if (!date || !time) return moment.invalid();
    return combineDateAndTime(date, time);
  }

  private mapTransportDtoToDetails(transportDto: TransportDto | null): TransportDetails | null {
    if (!transportDto) {
      return null;
    }

    return pickBy(
      {
        privateVehicle: transportDto.privateVehicleTravelTimeMinutes
          ? {
              hours: Math.trunc(transportDto.privateVehicleTravelTimeMinutes / 60),
              minutes: transportDto.privateVehicleTravelTimeMinutes % 60
            }
          : undefined,
        publicTransport:
          transportDto.publicTransportArrival || transportDto.publicTransportDeparture
            ? {
                arrival: transportDto.publicTransportArrival ?? null,
                departure: transportDto.publicTransportDeparture ?? null
              }
            : undefined
      },
      isDefined
    );
  }

  private getTransportDto(transportDetails: TransportDetails | null): TransportDto | null {
    if (!transportDetails) {
      return null;
    }

    const isUsingPublicTransport = isDefined(transportDetails.publicTransport);
    const isUsingPrivateVehicle = isDefined(transportDetails.privateVehicle);

    let privateVehicleTravelTimeMinutes = 0;
    if (transportDetails.privateVehicle) {
      privateVehicleTravelTimeMinutes =
        transportDetails.privateVehicle.hours! * 60 + transportDetails.privateVehicle.minutes!;
    }
    return {
      transportationType: this.getTransportationType(isUsingPublicTransport, isUsingPrivateVehicle),
      publicTransportDeparture: transportDetails.publicTransport?.departure ?? '',
      publicTransportArrival: transportDetails.publicTransport?.arrival ?? '',
      privateVehicleTravelTimeMinutes
    };
  }

  private getTransportationType(isUsingPublicTransport: boolean, isUsingPrivateVehicle: boolean): TransportationType {
    if (isUsingPrivateVehicle && isUsingPublicTransport) {
      return TransportationType.Combined;
    } else if (isUsingPublicTransport) {
      return TransportationType.PublicTransport;
    } else {
      return TransportationType.PrivateVehicle;
    }
  }
}
