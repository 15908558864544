import {Selector} from '@ngxs/store';
import {formatDate} from '@shared/shared-module/utils/date-time.utils';
import {isDefined} from '@shared/shared-module/utils/is-defined';
import {
  getRequestTypeTranslation,
  getTrackingStateTranslation,
  TranslationString
} from '@shared/shared-module/utils/translation.utils';
import {sortBy} from 'lodash';
import {Action, DescriptionDto, RequestDto, RequestState, RequestType} from '../../core/api/generated/msa-admin-query';
import {RequestDutyInfoDto} from '../../core/api/generated/msa-duty-service';
import {RequestTranslationsUtils, StatusFields} from '../../utils/translation.utils';
import {CreateReconsideration} from '../actions/edit-request.state.actions';
import {DeleteRequest, WithdrawLeaveRequest} from '../actions/requests.state.actions';
import {DutyStateSelectors} from './duty.state.selectors';
import {RequestsStateSelectors} from './requests.state.selectors';

export type RequestViewAction = {id: string; text: TranslationString; routerLink?: string; storeAction?: unknown};

export interface RequestView {
  id: string;
  type: RequestType;
  typeText: TranslationString;
  dutyType: DescriptionDto | null;
  from: string | null;
  submittedAt: string;
  state: RequestState;
  trackingState: TranslationString;
  actions: RequestViewAction[];
  clickRouterLink: string | null;
  showIncompleteInfo: boolean;
  stateTextAndColor: StatusFields<string>;
}

export class RequestsOverviewStateSelectors {
  @Selector([DutyStateSelectors.slices.dutyInfos, RequestsStateSelectors.slices.requests])
  static getRequestViews(duties: RequestDutyInfoDto[], requests: RequestDto[]): RequestView[] {
    // loop over all
    return sortBy(requests, r => r.submittedStateDateTime)
      .filter(r => r.requestType !== RequestType.Reconsideration)
      .flatMap(request => {
        const reconsiderationList: RequestDto[] = requests.filter(r => r.reconsidered === request.id);
        const duty = duties.find(d => d.dutyId === request.dutyId);
        if (!duty) return null;

        const result: RequestView[] = [];
        result.push(this.getRequestView(request, duty));
        for (const reconsideration of reconsiderationList) {
          result.push(this.getRequestView(reconsideration, duty));
        }
        return result.filter(isDefined);
      })
      .filter(isDefined);
  }

  private static getRequestView(request: RequestDto, duty: RequestDutyInfoDto): RequestView {
    const isReconsideration = request.requestType === RequestType.Reconsideration;
    const actions = RequestsOverviewStateSelectors.getRequestActions(request);

    return {
      id: request.id,
      type: request.requestType,
      typeText: getRequestTypeTranslation(request.requestType),
      dutyType: isReconsideration ? null : duty.dutyType,
      from: isReconsideration ? null : formatDate(duty.startDate),
      submittedAt: request.submittedStateDateTime ? formatDate(request.submittedStateDateTime) : '-',
      state: request.state,
      trackingState: getTrackingStateTranslation(request.trackingState),
      actions,
      // find first routerLink of all actions that have links
      clickRouterLink: actions.filter(a => isDefined(a?.routerLink)).find(isDefined)?.routerLink ?? null,
      showIncompleteInfo: request.state === RequestState.Incomplete,
      stateTextAndColor: RequestTranslationsUtils.getStatusFields(request.state)
    };
  }

  private static getRequestRouterLink(request: RequestDto): string {
    return `${request.requestType.toLowerCase()}/${request.id}`;
  }

  private static getRequestActions(request: RequestDto): Array<RequestViewAction> {
    return request.actions
      .map<RequestViewAction | null>(a => {
        switch (a) {
          case Action.EditRequest:
            return {
              id: 'edit',
              text: 'i18n.requests.actions.edit',
              routerLink: this.getRequestRouterLink(request)
            };
          case Action.ViewOverview:
            return {
              id: 'open',
              text: 'i18n.requests.actions.show',
              routerLink: this.getRequestRouterLink(request)
            };
          case Action.OpenReconsiderationRequest:
            return {
              id: 'reconsider',
              text: 'i18n.requests.actions.reconsider',
              storeAction: new CreateReconsideration(request.id)
            };
          case Action.WithdrawRequest:
            return {
              id: 'withdraw',
              text: 'i18n.requests.actions.withdraw',
              storeAction: new WithdrawLeaveRequest(request.id)
            };
          case Action.DeleteRequest:
            return {
              id: 'delete',
              text: 'i18n.requests.actions.delete',
              storeAction: new DeleteRequest(
                request.id,
                RequestsOverviewStateSelectors.getS3BucketType(request.requestType)
              ),
              routerLink: ''
            };
          default:
            return null;
        }
      })
      .filter(isDefined);
  }
  public static getS3BucketType(requestType: RequestType): 'MILO' | 'PISA' {
    switch (requestType) {
      case RequestType.Shift:
        return 'PISA';
      case RequestType.Reconsideration:
        return 'PISA';
      case RequestType.Leave:
        return 'MILO';
      default:
        throw new Error('Request type is not set!');
    }
  }
}
