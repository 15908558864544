/**
 * Msa Duty Service UI
 * REST API for Msa Duty Service
 *
 * The version of the OpenAPI document: 1.2.11
 * Contact: dimilar-devops@bit.admin.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Rank addition (Gradzusatz) Ja (\"iGst\" im Generalstab) Nein (\"kein\" default) \"RKD\"(Rotkreuzdienst) 
 */
export enum RankAddition {
    J = 'J',
    N = 'N',
    Rkd = 'RKD'
}

