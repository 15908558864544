import {Season} from '../../../../projects/admin-query/src/app/core/api/generated/msa-admin-query';
import {TranslationString} from './translation.utils';

export function getSeasonTranslation(season?: Season): TranslationString {
  switch (season) {
    case Season.Summer:
      return 'i18n.duties.gad.season.summer';
    case Season.Winter:
      return 'i18n.duties.gad.season.winter';
    case Season.Spring:
      return 'i18n.duties.gad.season.spring';
    case Season.Autumn:
      return 'i18n.duties.gad.season.autumn';
    default:
      throw new Error(`Unknown season ${season}!`);
  }
}
