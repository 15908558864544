import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, inject, input} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {Store} from '@ngxs/store';
import {GERMAN_SHORT_DATE_FORMAT, MONTH_YEAR_FORMAT} from '@shared/shared-module/utils/date-time.utils';
import {readStoreSignal} from '@shared/shared-module/utils/store.utils';
import {DisplacementTime, DocumentDto, RequestType} from '../../../core/api/generated/msa-admin-query';
import {DownloadAttachmentDocument, DownloadResponseDocument} from '../../../stores/actions/edit-request.state.actions';
import {CodeListStateSelectors} from '../../../stores/selectors/code-list.state.selectors';
import {DutyStateSelectors} from '../../../stores/selectors/duty.state.selectors';
import {EditRequestStateSelectors} from '../../../stores/selectors/edit-request-state.selectors';
import {RequestsStateSelectors} from '../../../stores/selectors/requests.state.selectors';
import {LeaveRequestSummaryComponent} from '../summary/leave/leave-request-summary.component';
import {ShiftRequestSummaryComponent} from '../summary/shift/shift-request-summary/shift-request-summary.component';
import {ShiftRequestUniversitySummaryComponent} from '../summary/shift/shift-university-request-summary/shift-university-request-summary.component';

/**
 * This component is only needed to provide the correct list
 * of steps to be displayed.
 */
@Component({
  selector: 'msa-request-summary',
  templateUrl: './request-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    LeaveRequestSummaryComponent,
    ShiftRequestSummaryComponent,
    ShiftRequestUniversitySummaryComponent
  ]
})
export class RequestSummaryComponent {
  public requestId = input.required<string>();
  public showDutyContext = input(false);

  private store = inject(Store);

  private readonly getRequestByIdFn = toSignal(this.store.select(RequestsStateSelectors.getRequestByIdFn));
  readonly request = computed(() => this.getRequestByIdFn()!(this.requestId())!);
  readonly duty = computed(
    () => this.store.selectSnapshot(DutyStateSelectors.getDutyInfoByIdFn)(this.request().dutyId)!
  );

  readonly isLeaveRequest = computed(() => EditRequestStateSelectors.isLeaveDetail(this.request()!.requestDetail));
  readonly requestType = computed(() => (this.isLeaveRequest() ? RequestType.Leave : RequestType.Shift));

  readonly isUniversityRequest = computed(() =>
    EditRequestStateSelectors.isUniversityDetail(this.request()!.requestDetail)
  );

  readonly reasonTypes = readStoreSignal(CodeListStateSelectors.slices.reasonTypes);
  readonly universityCodes = readStoreSignal(CodeListStateSelectors.slices.universityNames);
  readonly departmentCodes = readStoreSignal(CodeListStateSelectors.slices.universityDepartments);

  protected readonly DisplacementTime = DisplacementTime;
  protected readonly MONTH_YEAR_FORMAT = MONTH_YEAR_FORMAT;
  protected readonly GERMAN_SHORT_DATE_FORMAT = GERMAN_SHORT_DATE_FORMAT;

  downloadAttachment(document: DocumentDto) {
    this.store.dispatch(new DownloadAttachmentDocument(this.requestId(), document, this.requestType()));
  }

  downloadResponse(document: DocumentDto) {
    this.store.dispatch(new DownloadResponseDocument(this.requestId(), document, this.requestType()));
  }
}
