import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, input} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {KeyValueComponent} from '@shared/shared-module/components/key-value/key-value.component';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {TranslateObjectPipe} from '@shared/shared-module/pipes/translate-object.pipe';
import {formatDate} from '@shared/shared-module/utils/date-time.utils';
import {isDefined} from '@shared/shared-module/utils/is-defined';
import {getSubstitutionTimeTranslation} from '@shared/shared-module/utils/translation.utils';
import {sortBy} from 'lodash';
import {SUPPORTED_LANGUAGES} from 'projects/admin-query/src/app/constants/languages.const';
import {DetailsShiftFdtDto} from 'projects/admin-query/src/app/core/api/generated/msa-admin-query';
import {RequestDutyInfoDto} from 'projects/admin-query/src/app/core/api/generated/msa-duty-service';

/**
 * This component is only needed to provide the correct list
 * of steps to be displayed.
 */
@Component({
  selector: 'app-substitution-time-summary',
  templateUrl: './substitution-time-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    TranslateObjectPipe,
    MatOptionModule,
    SafeTranslatePipe,
    SafeTranslateDirective,
    KeyValueComponent
  ]
})
export class SubstituionTimeSummaryComponent {
  public requestDetails = input.required<DetailsShiftFdtDto>();
  public duty = input.required<RequestDutyInfoDto>();

  readonly formatDate = formatDate;

  readonly preventedPeriodRows = computed(() => {
    const duty = this.duty();
    if (!duty) return;

    const dutyDateRange = {from: duty.startDate, to: duty.endDate};
    const preventedPeriods =
      sortBy([...this.requestDetails().preventedPeriods, dutyDateRange], ['from', 'to'])
        .map(range => `${formatDate(range.from)} - ${formatDate(range.to)}`)
        .filter(isDefined) ?? [];
    return preventedPeriods.map(period => ({value: period, id: period.toString()}));
  });

  readonly languageRows = computed(() => {
    return SUPPORTED_LANGUAGES.map(lang => ({
      value: lang,
      id: lang,
      icon: this.requestDetails().language.some(l => l === lang) ? 'check' : 'close'
    }));
  });

  readonly substitutionTimeText = computed(() =>
    getSubstitutionTimeTranslation(this.requestDetails().displacementTime)
  );
}
