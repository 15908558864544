import {ChangeDetectionStrategy, Component, computed, input} from '@angular/core';
import {DatePipe} from '@angular/common';
import {MsaCollapsibleComponent} from '@shared/shared-module/components/msa-collapsible/msa-collapsible.component';
import {MsaEmptyListBoxComponent} from '@shared/shared-module/components/msa-empty-list-box/msa-empty-list-box.component';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {StatusPillComponent} from '@shared/shared-module/components/status-pill/status-pill.component';
import {DutyDetailsDto} from '../../../core/api/generated/msa-duty-service';
import {TranslateObjectPipe} from '@shared/shared-module/pipes/translate-object.pipe';
import {PersonDataStateSelectors} from '@shared/shared-module/stores/selectors/person-data.state.selectors';
import {readStoreSignal} from '@shared/shared-module/utils/store.utils';

@Component({
  selector: 'msa-military-postal-address',
  templateUrl: './military-postal-address.component.html',
  standalone: true,
  imports: [
    DatePipe,
    MsaCollapsibleComponent,
    MsaEmptyListBoxComponent,
    SafeTranslateDirective,
    SafeTranslatePipe,
    StatusPillComponent,
    TranslateObjectPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MilitaryPostalAddressComponent {
  duty = input.required<DutyDetailsDto>();
  private personalData = readStoreSignal(PersonDataStateSelectors.getPersonalData);
  private militaryData = readStoreSignal(PersonDataStateSelectors.getCurrentMilitaryData);

  rank = computed(() => this.militaryData()?.rankShort);
  rankAddition = computed(() => this.militaryData()?.rankAddition);
  firstName = computed(() => this.personalData().firstName);
  lastName = computed(() => this.personalData().lastName);
  division = computed(() => this.duty()?.divisionCode.descriptionDto);
  zipCode = computed(
    () => this.duty()?.divisionCode.additionalProperties?.find(e => e.name === 'militaryPostalCode')?.value
  );

  isRs = false; // logic will follow later to display message if rekrutenschule
}
