import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {ReasonTypeLeave} from '../../core/api/generated/msa-admin-query';

// this makes sure that we always have all texts defined
export const REASON_LEAVE_TEXTS: {[reason in ReasonTypeLeave]: TranslationString} = {
  BUSINESS: 'i18n.leave.reasonTypes.business',
  EDUCATION: 'i18n.leave.reasonTypes.education',
  PRIVATE: 'i18n.leave.reasonTypes.private',
  OTHERS: 'i18n.leave.reasonTypes.others'
};
