@if (label.length > 0) {
  <label class="msa-text tw-mb-6" [translate]="label" [appMarkRequired]="form.controls.start"></label>
}

<form class="tw-flex tw-flex-col d:tw-flex-row d:tw-gap-30" [formGroup]="form" data-cy="dateRangeForm">
  <div class="tw-flex-grow">
    <mat-form-field class="tw-w-full" data-cy="dateRange" subscriptSizing="dynamic">
      <mat-date-range-input [rangePicker]="rangepicker" [max]="maxDate()" [min]="minDate()">
        <input
          class="msa-input-field"
          id="dateRangeStart{{ id }}"
          [formControl]="form.controls.start"
          [attr.data-cy]="'dateRangeStart'"
          [placeholder]="placeholderFrom"
          (click)="rangepicker.open()"
          matStartDate
        />
        <input
          class="msa-input-field"
          id="dateRangeEnd{{ id }}"
          [formControl]="form.controls.end"
          [attr.data-cy]="'dateRangeEnd'"
          [placeholder]="placeholderTo"
          (click)="rangepicker.open()"
          matEndDate
        />
      </mat-date-range-input>
      <mat-datepicker-toggle [for]="rangepicker" matSuffix matIconSuffix />
      <mat-date-range-picker #rangepicker [touchUi]="true" startView="year" />

      @if (hint) {
        <mat-hint align="start">{{ hint }}</mat-hint>
      }
    </mat-form-field>
  </div>

  @if (showDelete()) {
    <div class="tw-flex tw-justify-end tw-pt-18 tw-text-black-light-5 d:tw-pt-0">
      <div class="tw-flex d:tw-h-[55px]">
        <button
          class="tw-flex tw-flex-row tw-items-center tw-gap-12 hover:tw-text-white"
          [disabled]="form.disabled"
          (click)="onRemove()"
          data-cy="dateRangeOnRemove"
        >
          <span class="tw-block d:tw-hidden" safeTranslate="i18n.common.removeItem"></span>
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  }
</form>
