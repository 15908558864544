<msa-collapsible title="i18n.duty.details.postalAddress.title">
  <div
    class="msa-text tw-grid tw-grid-cols-1 tw-gap-6 tw-bg-black-light-1 tw-px-18 tw-py-24 d:tw-grid d:tw-grid-cols-3 d:tw-p-30"
  >
    @if (isRs) {
      <div class="d:tw-col-span-3" [innerHtml]="'i18n.duty.details.postalAddress.isRs' | safeTranslate"></div>
    } @else if (!zipCode()) {
      <div class="d:tw-col-span-3" [innerHtml]="'i18n.duty.details.postalAddress.notYetKnown' | safeTranslate"></div>
    } @else {
      <div class="tw-text-black-light-5.5" safeTranslate="i18n.duty.details.postalAddress.yourPostalAddress"></div>
      <div class="d:tw-col-span-2">
        <div>
          {{ rank() | translateObject }}

          @if (rankAddition()) {
            <span>
              {{ rankAddition() | safeTranslate }}
            </span>
          }
          {{ firstName() }} {{ lastName() }}
        </div>
        <div>{{ division() | translateObject }}</div>
        <div>{{ "i18n.common.military" | safeTranslate }} {{ zipCode() }}</div>
      </div>
    }
  </div>
</msa-collapsible>
