import {CdkStepperModule} from '@angular/cdk/stepper';
import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, DestroyRef, inject, model} from '@angular/core';
import {takeUntilDestroyed, toSignal} from '@angular/core/rxjs-interop';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {Router} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {SnackbarService} from '@shared/shared-module/components/msa-snackbar/service/snackbar.service';
import {BottomBarService} from '@shared/shared-module/services/bottom-bar-service/bottom-bar.service';
import {ErrorMessageHandler} from '@shared/shared-module/services/error-message-handler/error-message.handler';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {FetchResourceAction, RequestFormAction} from 'projects/admin-query/src/app/models/request-form-actions.model';
import {UpdateRequest} from 'projects/admin-query/src/app/stores/actions/requests.state.actions';
import {RequestFormStateSelectors} from 'projects/admin-query/src/app/stores/selectors/request-stepper.state.selectors';
import {catchError, Observable, tap} from 'rxjs';
import {MsaStepComponent} from '../../../../../../../../src/app/shared-module/components/msa-step/msa-step.component';
import {MsaStepperComponent} from '../../../../../../../../src/app/shared-module/components/msa-stepper/msa-stepper.component';
import {
  LeaveRequestRestService,
  RequestDto,
  RequestType,
  ShiftRequestRestService
} from '../../../../core/api/generated/msa-admin-query';
import {
  CloseRequest,
  DownloadAttachmentDocument,
  RemoveDocument,
  SaveDraft,
  SubmitRequest,
  UpdateUserFormData
} from '../../../../stores/actions/edit-request.state.actions';
import {EditRequestStateSelectors} from '../../../../stores/selectors/edit-request-state.selectors';
import {StepPageWrapperComponent} from '../step-page-wrapper/step-page-wrapper.component';

/**
 * This component is only needed to provide the correct list
 * of steps to be displayed.
 */
@Component({
  selector: 'msa-request-form-stepper',
  templateUrl: './request-form-stepper.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    StepPageWrapperComponent,
    MsaStepperComponent,
    CdkStepperModule,
    MsaStepComponent
  ]
})
export class RequestFormStepperComponent {
  private store = inject(Store);
  private router = inject(Router);
  private snackbarService = inject(SnackbarService);
  private bottomBarService = inject(BottomBarService);
  private errorMessageHandler = inject(ErrorMessageHandler);
  private destroyRef = inject(DestroyRef);

  private shiftRequestService = inject(ShiftRequestRestService);
  private leaveRequestRestService = inject(LeaveRequestRestService);

  currentIndex = model(0);
  currentStep = computed(() => this.currentIndex() + 1);
  numSteps = computed(() => this.steps()?.length);
  canGoBack = computed(() => this.currentIndex() >= 1);
  canGoNext = computed(() => this.currentIndex() < this.numSteps());

  steps = toSignal(this.store.select(RequestFormStateSelectors.formSteps), {
    initialValue: []
  });
  selectedStepTitle = computed(() => this.steps()?.[this.currentIndex()]?.title ?? '');
  private requestType = toSignal(this.store.select(EditRequestStateSelectors.getRequestType));
  private requestId = this.store.selectSnapshot(EditRequestStateSelectors.slices.requestId)!;

  formTitle = computed<TranslationString>(() => {
    const requestType = this.requestType();
    if (!requestType) throw new Error('Request type is not set!');
    switch (requestType) {
      case RequestType.Shift:
        return 'i18n.duty-type.form.header-description.text';
      case RequestType.Reconsideration:
        return 'i18n.relocation.recap.reconsider-request';
      case RequestType.Leave:
        return 'i18n.leave.header.main';
      default:
        throw new Error(`Request type ${requestType} unknown!`);
    }
  });

  closeRequest(): void {
    this.router.navigateByUrl('/admin-query/requests').then(hasNavigated => {
      if (!hasNavigated) return;
      this.store.dispatch(new CloseRequest());
    });
  }

  goBack(): void {
    this.decrementStep();
  }

  goNextIfValid<T>(validation$: Observable<T>) {
    validation$
      .pipe(
        tap(() => this.snackbarService.dismissAll()),
        tap(() => this.incrementStep()),
        tap(() => {
          this.bottomBarService.show({text: 'i18n.common.will-be-saved', icon: 'cloud'});
        }),
        catchError((err: unknown) => this.errorMessageHandler.logAndThrow(err)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  fetchResource(action: FetchResourceAction): void {
    this.store.dispatch(action);
  }

  onRequestFormAction(requestFormAction: RequestFormAction): any {
    switch (requestFormAction.type) {
      case 'saveDraft':
        return this.store.dispatch(new SaveDraft());
      case 'submit':
        return this.goNextIfValid(this.store.dispatch(new SubmitRequest()));
      case 'close':
        return this.store.dispatch(new CloseRequest());
      case 'updateUserFormData':
        return this.store.dispatch(new UpdateUserFormData(requestFormAction.payload));
      case 'downloadAttachment':
        return this.store.dispatch(
          new DownloadAttachmentDocument(this.requestId, requestFormAction.payload, this.getRequestTypeEnum())
        );
      case 'removeAttachment':
        return this.store.dispatch(new RemoveDocument(requestFormAction.payload));
    }

    const formSpecificAction$ = this.getFormSpecificAction$(requestFormAction);

    this.goNextIfValid(
      formSpecificAction$.pipe(
        tap(requestDto => this.store.dispatch(new UpdateRequest(requestDto))),
        catchError(response => this.errorMessageHandler.logAndIgnore(response))
      )
    );
  }

  private getFormSpecificAction$(requestFormAction: RequestFormAction): Observable<RequestDto> {
    switch (requestFormAction.type) {
      case 'updateReasonType':
        return this.shiftRequestService.updateReasonType(this.requestId, requestFormAction.payload);
      case 'updateReason': {
        const updateObservable = EditRequestStateSelectors.isLeaveDetail(
          this.store.selectSnapshot(EditRequestStateSelectors.getCurrentEditingRequest)!.requestDetail
        )
          ? this.leaveRequestRestService.updateLeaveReason(this.requestId, requestFormAction.payload)
          : this.shiftRequestService.updateReason(this.requestId, requestFormAction.payload);
        return updateObservable;
      }
      case 'updateDutyReplacementGad':
        return this.shiftRequestService.updateDutyReplacement(this.requestId, requestFormAction.payload);
      case 'updatePreventedPeriods':
        return this.shiftRequestService.updatePreventedPeriods(this.requestId, requestFormAction.payload);
      case 'updateUniversity':
        return this.shiftRequestService.updateUniversity(this.requestId, requestFormAction.payload);
      case 'updateUniversitySchedules':
        return this.shiftRequestService.updateUniversitySchedule(this.requestId, requestFormAction.payload);
      case 'updateTransportDetailsDto':
        return this.leaveRequestRestService.updateLeaveAndTransportDetails(this.requestId, requestFormAction.payload);
      case 'updatePrivileges':
        return this.leaveRequestRestService.updateLeavePrivileges(this.requestId, requestFormAction.payload);
      default:
        throw new Error(`Unhandeled form action ${requestFormAction.type}!`);
    }
  }

  private incrementStep(): void {
    this.currentIndex.update(v => Math.min(this.numSteps(), v + 1));
  }

  private decrementStep(): void {
    this.currentIndex.update(v => Math.max(0, v - 1));
  }

  private getRequestTypeEnum(): RequestType {
    const requestType = this.requestType();
    if (!requestType) throw new Error('Request type is not set!');
    switch (requestType) {
      case RequestType.Shift:
        return RequestType.Shift;
      case RequestType.Reconsideration:
        return RequestType.Shift;
      case RequestType.Leave:
        return RequestType.Leave;
      default:
        throw new Error('Request type is not set!');
    }
  }
}
