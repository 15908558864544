import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, effect, inject, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {DateRange} from '@angular/material/datepicker';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {TranslateModule} from '@ngx-translate/core';
import {MessageType} from '@shared/shared-module/components/enums/messageType';
import {FormStepperNavigationComponent} from '@shared/shared-module/components/form-stepper-navigation/form-stepper-navigation.component';
import {MsaContentNoticeComponent} from '@shared/shared-module/components/msa-content-notice/msa-content-notice.component';
import {MsaFileUploadComponent} from '@shared/shared-module/components/msa-file-upload/msa-file-upload.component';
import {MsaSaveDraftComponent} from '@shared/shared-module/components/msa-save-draft/msa-save-draft.component';
import {RequiredFieldsNoticeComponent} from '@shared/shared-module/components/required-fields-notice/required-fields-notice.component';
import {DEFAULT_DEBOUNCE_TIME_MS} from '@shared/shared-module/config/rxjs.config';
import {MarkRequiredDirective} from '@shared/shared-module/directives/mark-required.directive';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {ENGLISH_SHORT_DATE_FORMAT, formatDate, stringToDate} from '@shared/shared-module/utils/date-time.utils';
import {debounceTime, tap} from 'rxjs';
import {DutyContextComponent} from '../../../../components/duty-context/duty-context.component';
import {RequestContextComponent} from '../../../../components/request-context/request-context.component';
import {
  DetailsShiftFdtUniversityDto,
  DocumentDto,
  FixedCodeHashes,
  RequestDto,
  RequestType,
  UniversityDateRangeReasonDto,
  UpdateUniversityScheduleDto
} from '../../../../core/api/generated/msa-admin-query';
import {RequestDocumentUploadService} from '../../../../services/request-document-upload.service';
import {MsaDateRangeComponent} from '../../general/date-range/date-range.component';
import {RequestIncompleteNoticeComponent} from '../../general/request-incomplete-notice/request-incomplete-notice.component';
import {StepEditRequestComponent} from '../../general/step-edit-request-component/step-edit-request.component';

interface UniversityForm {
  schedules: FormArray<FormGroup<UniversityScheduleForm>>;
  reason: FormControl<string | null>;
  documents: FormControl<string[]>;
}

interface UniversityScheduleForm {
  dateRange: FormControl<DateRange<Date> | null>;
  scheduleReason: FormControl<string>;
}

@Component({
  selector: 'msa-step-detailed-explanation-university',
  templateUrl: './step-detailed-explanation-university.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    MsaContentNoticeComponent,
    FormsModule,
    MatInputModule,
    FormStepperNavigationComponent,
    DutyContextComponent,
    MarkRequiredDirective,
    RequiredFieldsNoticeComponent,
    MsaFileUploadComponent,
    RequestIncompleteNoticeComponent,
    MsaSaveDraftComponent,
    RequestContextComponent,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MsaDateRangeComponent,
    SafeTranslatePipe,
    SafeTranslateDirective
  ],
  providers: [RequestDocumentUploadService]
})
export class StepDetailedExplanationUniversityComponent extends StepEditRequestComponent implements OnInit {
  readonly COMMENT_MAX_LENGTH = 500;

  form = new FormGroup<UniversityForm>({
    schedules: new FormArray<FormGroup<UniversityScheduleForm>>([]),
    documents: new FormControl([], {
      nonNullable: true
    }),
    reason: new FormControl(null, {
      validators: [Validators.maxLength(this.COMMENT_MAX_LENGTH)]
    })
  });

  public requestDocumentUploadService = inject(RequestDocumentUploadService);
  protected readonly RequestType = RequestType;
  protected readonly MessageType = MessageType;
  protected schedulesControl = this.form.controls.schedules;

  documents = computed(() => this.request().requestDetail.documents);
  pendingUploads$ = this.requestDocumentUploadService.hasPendingUploads$;

  constructor() {
    super();
    this.form.valueChanges
      .pipe(
        debounceTime(DEFAULT_DEBOUNCE_TIME_MS),
        tap(values => {
          this.action.emit({
            type: 'updateUserFormData',
            payload: {
              reason: values.reason ?? undefined,
              university: {
                schedules: this.getUniversityDateRangeReasonDtoFromForm()
              }
            }
          });
        }),
        takeUntilDestroyed()
      )
      .subscribe();

    effect(() => {
      // set validators for documents
      this.form.controls.documents.removeValidators([Validators.required]);
      if (this.isOtherUniOrDep(this.request())) {
        this.form.controls.documents.addValidators([Validators.required]);
      }
      const uniDetail: DetailsShiftFdtUniversityDto = this.request().requestDetail as DetailsShiftFdtUniversityDto;
      this.form.controls.documents.patchValue(uniDetail.documents.map(d => d.id));
      this.form.controls.documents.updateValueAndValidity();
    });
  }

  ngOnInit(): void {
    // arrays can actually not be patched. therefore we need to add them via form controls.
    const schedules = (<DetailsShiftFdtUniversityDto>this.request().requestDetail).university.schedules.map(value => ({
      scheduleReason: value.scheduleReason,
      dateRange: new DateRange<Date>(
        stringToDate(value.from, ENGLISH_SHORT_DATE_FORMAT),
        stringToDate(value.to, ENGLISH_SHORT_DATE_FORMAT)
      )
    }));
    schedules.forEach(e => {
      this.addSchedule(e.scheduleReason, e.dateRange);
    });

    this.form.patchValue({
      reason: this.request().requestDetail.reason
    });
  }

  onNext(): void {
    this.action.emit({type: 'updateUniversitySchedules', payload: this.getUpdateUniSchedule()});
  }

  onSaveClick() {
    this.action.emit({type: 'saveDraft'});
  }

  private getUniversityDateRangeReasonDtoFromForm(): UniversityDateRangeReasonDto[] {
    return (
      this.form.value.schedules?.map(value => ({
        scheduleReason: value.scheduleReason!,
        from: formatDate(value.dateRange?.start, ENGLISH_SHORT_DATE_FORMAT),
        to: formatDate(value.dateRange?.end, ENGLISH_SHORT_DATE_FORMAT)
      })) ?? []
    );
  }

  getUpdateUniSchedule(): UpdateUniversityScheduleDto {
    const schedules: UniversityDateRangeReasonDto[] = this.getUniversityDateRangeReasonDtoFromForm();
    const reason = this.form.value.reason;

    return {examDates: schedules, reason: reason ?? undefined};
  }

  addSchedule(reason = '', dateRange: DateRange<Date> | null = null): void {
    this.schedulesControl.push(
      new FormGroup<UniversityScheduleForm>({
        scheduleReason: new FormControl(reason, {nonNullable: true, validators: [Validators.required]}),
        dateRange: new FormControl<DateRange<Date> | null>(dateRange, {
          nonNullable: true,
          validators: [Validators.required]
        })
      })
    );
  }

  removeSchedule(index: number): void {
    this.schedulesControl.removeAt(index);
  }

  removeDocument(documentId: string): void {
    this.action.emit({type: 'removeAttachment', payload: documentId});
  }

  downloadDocument(document: DocumentDto) {
    this.action.emit({type: 'downloadAttachment', payload: document});
  }

  isOtherUniOrDep(request: RequestDto | null): boolean {
    if (request == null) {
      return false;
    }

    const uniDetail: DetailsShiftFdtUniversityDto = request.requestDetail as DetailsShiftFdtUniversityDto;
    return (
      uniDetail.university.university === FixedCodeHashes.OtherUniversity ||
      uniDetail.university.universityDepartment === FixedCodeHashes.OtherDepartment
    );
  }
}
