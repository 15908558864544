import {createPropertySelectors, Selector} from '@ngxs/store';
import {translateObject} from '@shared/shared-module/pipes/translate-object.pipe';
import {getOtherUniversity} from '@shared/shared-module/utils/code-utils';
import {orderBy} from 'lodash';
import {FixedCodeHashes, FixedHashCodeEntryDto} from '../../core/api/generated/msa-admin-query';
import {CodeDtoV2} from '../../core/api/generated/msa-duty-service';
import {CodeListStateModel} from '../models/code-list.state.model';
import {CodeListState} from '../states/code-list.state';

export class CodeListStateSelectors {
  static slices = createPropertySelectors<CodeListStateModel>(CodeListState);

  @Selector([CodeListStateSelectors.slices.fixedHashCodes])
  static getFixedHashCodeByName(fixedHashCodes: FixedHashCodeEntryDto[]): (codeName: FixedCodeHashes) => string | null {
    return (codeName: FixedCodeHashes) => fixedHashCodes.find(code => code.name === codeName)?.hashCode ?? null;
  }

  @Selector([CodeListStateSelectors.slices.universityNames])
  static getUniversityByCode(universityNames: CodeDtoV2[]): (universityCode: string) => CodeDtoV2 | null {
    return (universityCode: string) => {
      if (universityCode == FixedCodeHashes.OtherUniversity) {
        return getOtherUniversity();
      }
      return universityNames.find(u => u.codeHash === universityCode) ?? null;
    };
  }

  public static filterUniversitiesByName(
    universities: CodeDtoV2[],
    value: string | CodeDtoV2,
    currentLang: string
  ): CodeDtoV2[] {
    const filterValue = typeof value === 'string' ? value : translateObject(value.descriptionDto, currentLang);

    let filtered = universities.filter(university => {
      const universityQueryText = `${translateObject(university.descriptionDto, currentLang)} ${translateObject(
        university.shortDescriptionDto,
        currentLang
      )}`;
      return universityQueryText?.toLowerCase().includes(filterValue.toLowerCase());
    });

    filtered = orderBy(filtered, item => translateObject(item.descriptionDto, currentLang));
    filtered.push(getOtherUniversity());

    return filtered;
  }
}
