import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, Inject, inject, OnInit, signal} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatDialog} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {MessageType} from '@shared/shared-module/components/enums/messageType';
import {MsaCardComponent} from '@shared/shared-module/components/msa-card/msa-card.component';
import {MsaDialogComponent} from '@shared/shared-module/components/msa-dialog/msa-dialog.component';
import {SnackbarService} from '@shared/shared-module/components/msa-snackbar/service/snackbar.service';
import {MsaSpinnerComponent} from '@shared/shared-module/components/msa-spinner/msa-spinner.component';
import {MsaTimelineComponent} from '@shared/shared-module/components/msa-timeline/msa-timeline.component';
import {MsaTooltipComponent} from '@shared/shared-module/components/msa-tooltip/msa-tooltip.component';
import {StatusPillComponent} from '@shared/shared-module/components/status-pill/status-pill.component';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {TranslateObjectPipe} from '@shared/shared-module/pipes/translate-object.pipe';
import {isDefined} from '@shared/shared-module/utils/is-defined';
import {TranslationString} from '@shared/shared-module/utils/translation.utils';
import {chain} from 'lodash';
import moment from 'moment';
import {catchError, EMPTY, take, tap} from 'rxjs';
import {MsaContentNoticeComponent} from '../../../../../../src/app/shared-module/components/msa-content-notice/msa-content-notice.component';
import {
  Action,
  DoneDutyDto,
  DutiesRestService,
  DutyMsaStateDto,
  MarchingOrderDocumentDtoTypeEnum,
  OpenDutyDto
} from '../../core/api/generated/msa-duty-service';
import {CreateRequestDialogComponent} from '../../dialogs/duties-dialog/create-request-dialog.component';
import {DutyTranslationsUtils} from '../../utils/translation.utils';
import {MarchingOrderDocumentComponent} from './marching-order-document/marching-order-document.component';

type DutyAction = {text: TranslationString; callback?: () => void};

@Component({
  selector: 'msa-duties-overview',
  templateUrl: './duties-overview.component.html',
  styleUrl: './duties-overview.component.css',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    RouterLink,
    MatMenuModule,
    MatIconModule,
    MsaTimelineComponent,
    MsaCardComponent,
    TranslateObjectPipe,
    SafeTranslatePipe,
    SafeTranslateDirective,
    StatusPillComponent,
    MsaTooltipComponent,
    MarchingOrderDocumentComponent,
    MsaSpinnerComponent,
    MsaContentNoticeComponent
  ]
})
export class DutiesOverviewComponent implements OnInit {
  private dutyService = inject(DutiesRestService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  protected readonly MarchingOrderDocumentDtoTypeEnum = MarchingOrderDocumentDtoTypeEnum;

  private openDuties = signal<Array<OpenDutyDto> | undefined>(undefined);
  private doneDuties = signal<Array<DoneDutyDto> | undefined>(undefined);

  openDutiesViewModels = computed(() =>
    chain(
      (this.openDuties() ?? []).map(openDuty => ({
        ...openDuty,
        ...DutyTranslationsUtils.getStatusFields(openDuty.msaDutyState),
        actions: this.getEnrichedActions(openDuty.id, openDuty.actions)
      }))
    )
      .groupBy(item => moment(item.startDate).year())
      .mapValues(values => values.sort((a, b) => Date.parse(b.startDate!) - Date.parse(a.startDate!)))
      .mapValues(values =>
        values.map(item => ({
          ...item,
          detachmentCode: item.detachmentCode!.map(dc => ({
            ...dc
          }))
        }))
      )
      .value()
  );

  doneDutiesViewModels = computed(() =>
    chain(
      (this.doneDuties() ?? []).map(doneDuty => ({
        ...doneDuty,
        ...DutyTranslationsUtils.getStatusFields(doneDuty.msaDutyState),
        actions: this.getEnrichedActions(doneDuty.id, doneDuty.actions)
      }))
    )
      .groupBy(item => moment(item.startDate).year())
      .mapValues(values => values.sort((a, b) => Date.parse(b.startDate!) - Date.parse(a.startDate!)))
      .value()
  );

  public isOpenDutiesLoading = computed(() => this.openDuties() === undefined && !this.error());
  public isDoneDutiesLoading = computed(() => this.doneDuties() === undefined && !this.error());

  public error = signal<unknown>(null);
  public plannedValue = DutyMsaStateDto.Planned;
  public completedState = DutyMsaStateDto.Completed;

  snackbarService = Inject(SnackbarService);
  translateService = Inject(TranslateService);

  constructor(private dialog: MatDialog) {
    this.dutyService
      .getAllDuties()
      .pipe(
        take(1),
        tap(() => this.error.set(null)),
        tap(duties => {
          this.openDuties.set(duties.openDuties);
          this.doneDuties.set(duties.doneDuties);
        }),
        catchError((err: unknown) => {
          this.error.set(err);
          return EMPTY;
        }),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  ngOnInit(): void {
    // TODO will be implemented in Story DMLRINF-2075
    // is seasonNumber-year
    // 1: winter
    // 2: spring
    // 3: summer
    // 4: autumn
    // 3-2024 is Summer 2024
    if (this.activatedRoute.snapshot.queryParamMap.has('wzp')) {
      this.dialog.open(MsaDialogComponent, {});
    }
  }

  protected getEnrichedActions(dutyId: string, dutyActions: Action[] | undefined): DutyAction[] {
    return (dutyActions ?? [])
      .map<DutyAction | null>((action: Action) => {
        switch (action) {
          case Action.OpenDetails:
            return {
              text: 'i18n.duties.actions.open',
              callback: () => this.router.navigate([this.router.url, dutyId])
            };
          case Action.DialogShiftAndLeaveRequests:
            return {
              text: 'i18n.duties.headers.shift-or-leave-duty-request',
              callback: () => this.openDialog(dutyId)
            };
          case Action.ReportError:
            return {text: 'i18n.duties.actions.reportError'};
          default:
            return null;
        }
      })
      .filter(isDefined);
  }

  openDialog(dutyId: string) {
    try {
      this.dialog.open(CreateRequestDialogComponent, {
        data: dutyId
      });
    } catch (error) {
      this.snackbarService.open(this.translateService.instant('i18n.common.error.generic'), MessageType.Error);
    }
  }

  onClick(callback?: () => void): void {
    if (callback) callback();
  }
}
