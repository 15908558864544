/**
 * Msa Duty Service UI
 * REST API for Msa Duty Service
 *
 * The version of the OpenAPI document: 1.2.11
 * Contact: dimilar-devops@bit.admin.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MarchingOrderDocumentDto { 
    type?: MarchingOrderDocumentDtoTypeEnum;
    documentId?: string;
    documentName?: string;
}
export enum MarchingOrderDocumentDtoTypeEnum {
    None = 'NONE',
    NotYetIssued = 'NOT_YET_ISSUED',
    DoNotMarch = 'DO_NOT_MARCH',
    Paper = 'PAPER',
    Digital = 'DIGITAL'
};



