@if (showDetails()) {
  <msa-collapsible title="i18n.duty.details.publicTransport.title">
    <div header>
      <msa-tooltip
        tooltipTitle="i18n.duty.details.publicTransport.swissPassTooltip.title"
        tooltipContent="i18n.duty.details.publicTransport.swissPassTooltip.text"
      ></msa-tooltip>
    </div>

    @if (isLoading()) {
      <msa-spinner class="tw-size-30 tw-text-navy"></msa-spinner>
    } @else {
      @if (!swissPassConnected()) {
        <app-msa-content-notice type="Warning">
          <p [innerHTML]="'i18n.duty.details.publicTransport.marchingOrderDisclaimer' | safeTranslate"></p>
        </app-msa-content-notice>
        <div class="tw-mt-18"></div>
      }

      <div class="tw-flex tw-gap-30 tw-bg-black-light-1">
        <div class="tw-h-auto tw-w-[218px] tw-place-content-center tw-bg-[#E30613]">
          <img
            class="tw-p-9 d:tw-p-15"
            ngSrc="assets/images/swisspass/swisspass_logo.svg"
            alt="SwissPass Logo"
            width="218"
            height="146"
          />
        </div>

        <div class="tw-flex tw-flex-col tw-pb-24 tw-pt-24">
          @if (swissPassConnected()) {
            <p class="msa-text" safeTranslate="i18n.duty.details.publicTransport.swissPassConnected"></p>

            <app-key-value
              [value]="publicTransportDetails()!.customerKeyNumber"
              key="i18n.duty.details.publicTransport.labels.customerId"
            ></app-key-value>
            <app-key-value
              [value]="publicTransportDetails()!.transportationClassText"
              key="i18n.duty.details.publicTransport.labels.class"
            ></app-key-value>
            @if (publicTransportDetails()!.hasTransportVoucher) {
              <app-key-value key="i18n.duty.details.publicTransport.labels.transportVoucher">
                <div class="msa-text tw-flex tw-items-center tw-gap-12">
                  <mat-icon class="tw-text-white">check</mat-icon>
                  <p safeTranslate="i18n.common.yes"></p>
                  <msa-tooltip
                    tooltipTitle="i18n.duty.details.publicTransport.transportVoucherTooltip.title"
                    tooltipContent="i18n.duty.details.publicTransport.transportVoucherTooltip.text"
                  />
                </div>
              </app-key-value>
            }
          } @else {
            <p
              class="msa-text tw-text-black-light-5"
              safeTranslate="i18n.duty.details.publicTransport.swissPassNotConnected"
            ></p>
            <msa-button class="tw-w-fit" (click)="connectSwissPass()" color="secondary">{{
              "i18n.duty.details.publicTransport.connectSwissPass" | safeTranslate
            }}</msa-button>
          }
        </div>
      </div>
    }
  </msa-collapsible>
}
