<hr class="tw-mb-30 tw-mt-30 tw-w-full tw-border-t tw-border-t-black-light-5 d:tw-mb-60 d:tw-mt-60" />

<h3
  class="msa-text-field-set tw-mb-9 d:tw-mb-18"
  safeTranslate="i18n.duty-relocation-form.period.substitution.label"
  data-cy="headerPreventedPeriods"
></h3>

@if (requestDetails().displacementTime) {
  <app-key-value
    [value]="substitutionTimeText()"
    [valueParams]="{ startDate: formatDate(duty().startDate), endDate: formatDate(duty().endDate) }"
    key="i18n.recap-container.period.possible-duty-time"
    data-cy="preventedPeriods"
  />
}

<app-key-value key="i18n.recap-container.period.impossible-duty-time.text" data-cy="languageFormations">
  @for (row of preventedPeriodRows(); track row.id) {
    <p class="tw-flex tw-items-center">
      {{ row.value | translate }}
    </p>
  }
</app-key-value>

@if (requestDetails().language.length > 0) {
  <app-key-value key="i18n.recap-container.language-title" data-cy="languageFormations">
    @for (row of languageRows(); track row.id) {
      <p class="tw-flex tw-items-center">
        <mat-icon class="me-3">{{ row.icon }}</mat-icon>
        {{ row.value | translate }}
      </p>
    }
  </app-key-value>
}
