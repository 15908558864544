@if (isLeaveRequest()) {
  <app-leave-request-summary
    [request]="request()"
    [showDutyContext]="showDutyContext()"
    (downloadAttachment)="downloadAttachment($event)"
  >
    <ng-container *ngTemplateOutlet="contentTemplate" />
  </app-leave-request-summary>
} @else {
  @if (isUniversityRequest()) {
    <app-shift-university-request-summary
      [request]="request()"
      [duty]="duty()"
      [reasonTypes]="reasonTypes()"
      [universityCodes]="universityCodes()"
      [departmentCodes]="departmentCodes()"
      [showDutyContext]="showDutyContext()"
      (downloadAttachment)="downloadAttachment($event)"
      (downloadResponse)="downloadResponse($event)"
    >
      <ng-container *ngTemplateOutlet="contentTemplate" />
    </app-shift-university-request-summary>
  } @else {
    <app-shift-request-summary
      [request]="request()"
      [showDutyContext]="showDutyContext()"
      [reasonTypes]="reasonTypes()"
      (downloadAttachment)="downloadAttachment($event)"
      (downloadResponse)="downloadResponse($event)"
    >
      <ng-container *ngTemplateOutlet="contentTemplate" />
    </app-shift-request-summary>
  }
}

<ng-template #contentTemplate>
  <ng-content />
</ng-template>
