<msa-request-incomplete-notice />

<app-duty-context />

<form [formGroup]="$any(form)" data-cy="reasonTimeForm">
  <label class="msa-text" [appMarkRequired]="form.controls.reason" safeTranslate="i18n.leave.reasonTime.reason"></label>

  <mat-form-field class="tw-w-full" appearance="outline" data-cy="leaveReason" subscriptSizing="dynamic">
    <mat-select [formControl]="form.controls.reason">
      @for (leaveReason of options; track leaveReason.code) {
        <mat-option [value]="leaveReason.code">{{ leaveReason.text | safeTranslate }}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  <p class="msa-text-field-set tw-mb-9 tw-mt-15 d:tw-mt-60" safeTranslate="i18n.leave.reasonTime.from"></p>

  <div class="tw-grid tw-grid-cols-2 tw-gap-12">
    <msa-date-picker class="tw-w-full" [formControl]="form.controls.fromDate" data-cy="leaveDateFrom" />

    <msa-time-picker class="tw-w-full" [formControl]="form.controls.fromTime" data-cy="leaveTimeFrom" />
  </div>

  <p class="msa-text-field-set tw-mt-15 d:tw-mt-60" safeTranslate="i18n.leave.reasonTime.to"></p>

  <div class="tw-mb-30 tw-grid tw-grid-cols-2 tw-gap-12 d:tw-mb-60">
    <msa-date-picker class="tw-w-full" [formControl]="form.controls.toDate" data-cy="leaveDateTo" />

    <msa-time-picker class="tw-w-full" [formControl]="form.controls.toTime" data-cy="leaveTimeTo" />
  </div>

  <msa-text-input
    id="destination"
    #destination
    [formControl]="form.controls.destination"
    [maxLength]="170"
    data-cy="destination"
    label="i18n.leave.reasonTime.destination"
  >
    <div hintContainer>
      <div class="msa-text-small tw-text-black-light-5">
        <p class="tw-flex tw-justify-between">
          <span>{{ "i18n.leave.reasonTime.destinationHint" | safeTranslate }}</span>
          <span class="tw-break-keep">{{ form.value.destination?.length ?? 0 }}&nbsp;/&nbsp;170</span>
        </p>
      </div>
    </div>
  </msa-text-input>

  <p class="msa-text-field-set tw-mb-12 tw-mt-30 d:tw-mt-60" safeTranslate="i18n.leave.reasonTime.meansOfTransport"></p>

  <app-leave-transport
    [formControl]="form.controls.travelOutbound"
    data-cy="transportOutboundTime"
    label="i18n.leave.reasonTime.travelOutbound"
  />

  <div class="tw-mt-15 d:tw-mt-30"></div>

  <app-leave-transport
    [formControl]="form.controls.travelReturn"
    data-cy="transportReturnTime"
    label="i18n.leave.reasonTime.travelReturn"
  />
</form>

<div class="mt-4"></div>

<app-form-stepper-navigation
  [showBackButton]="false"
  [preventNext]="form.invalid"
  (next)="onNext()"
  (previous)="back.emit()"
/>

<msa-save-draft [showSaveDraft]="hasUnsavedChanges()" (saveDraft)="onSaveClick()" />

<app-required-fields-notice />
