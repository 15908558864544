<msa-request-incomplete-notice />

@if (request().requestType !== RequestType.Reconsideration) {
  <app-duty-context />
} @else {
  <msa-request-context [requestId]="request().id" [dutyId]="request().dutyId" />
}

<form [formGroup]="form" data-cy="shiftReasonForm">
  <div>
    <label
      class="msa-text tw-mb-6"
      [appMarkRequired]="form.controls.reasonType"
      safeTranslate="i18n.duty-relocation-form.reason.label"
    ></label>
    <mat-form-field class="tw-w-full" subscriptSizing="dynamic">
      <mat-select [formControl]="form.controls.reasonType" data-cy="reasonType">
        @for (reasonType of reasonTypes(); track reasonType.code) {
          <mat-option [value]="reasonType.code">{{
            reasonType.descriptionDto
              ? (reasonType.descriptionDto | translateObject)
              : ("i18n.code.not-found" | translate)
          }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
</form>

<div class="tw-mt-30 d:tw-mt-60"></div>

<app-form-stepper-navigation
  [showBackButton]="false"
  [preventNext]="form.invalid"
  (next)="onNext()"
  (previous)="back.emit()"
/>

<msa-save-draft [showSaveDraft]="hasUnsavedChanges()" (saveDraft)="onSaveClick()" />

<app-required-fields-notice />
