/**
 * Msa Duty Service UI
 * REST API for Msa Duty Service
 *
 * The version of the OpenAPI document: 1.2.11
 * Contact: dimilar-devops@bit.admin.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum Action {
    ReportError = 'REPORT_ERROR',
    CreateLeaveRequest = 'CREATE_LEAVE_REQUEST',
    CreateShiftRequest = 'CREATE_SHIFT_REQUEST',
    DeleteRequest = 'DELETE_REQUEST',
    EditRequest = 'EDIT_REQUEST',
    OpenReconsiderationRequest = 'OPEN_RECONSIDERATION_REQUEST',
    SubmitRequest = 'SUBMIT_REQUEST',
    ViewOverview = 'VIEW_OVERVIEW',
    WithdrawRequest = 'WITHDRAW_REQUEST',
    OpenDetails = 'OPEN_DETAILS',
    DialogShiftAndLeaveRequests = 'DIALOG_SHIFT_AND_LEAVE_REQUESTS'
}

