import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, inject, input} from '@angular/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {TranslateModule} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {MsaCollapsibleComponent} from '@shared/shared-module/components/msa-collapsible/msa-collapsible.component';
import {StatusPillComponent} from '@shared/shared-module/components/status-pill/status-pill.component';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {TranslateObjectPipe} from '@shared/shared-module/pipes/translate-object.pipe';
import {EMPTY} from 'rxjs';
import {MsaAccordionComponent} from '../../../../../../src/app/shared-module/components/msa-accordion/msa-accordion.component';
import {RequestSummaryComponent} from '../../pages/request-overview/request-summary/request-summary.component';
import {RequestsStateSelectors} from '../../stores/selectors/requests.state.selectors';
import {DutyStateSelectors} from '../../stores/selectors/duty.state.selectors';
import {RequestTranslationsUtils} from '../../utils/translation.utils';

@Component({
  selector: 'msa-request-context',
  templateUrl: './request-context.component.html',
  styleUrl: './request-context.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    TranslateObjectPipe,
    MatExpansionModule,
    MsaCollapsibleComponent,
    StatusPillComponent,
    RequestSummaryComponent,
    SafeTranslatePipe,
    SafeTranslateDirective,
    MsaAccordionComponent
  ]
})
export class RequestContextComponent {
  public requestId = input.required<string>();
  public dutyId = input.required<string>();

  private store = inject(Store);

  public panelOpenState = false;
  public affectedRequest = computed(() =>
    this.store.selectSnapshot(RequestsStateSelectors.getReconsideredRequestByIdFn)(this.requestId())
  );

  public affectedDuty = computed(() => this.store.selectSnapshot(DutyStateSelectors.getDutyInfoByIdFn)(this.dutyId()));
  public contextData = computed(() => {
    const affectedDuty = this.affectedDuty();
    const affectedRequest = this.affectedRequest();
    if (!affectedDuty || !affectedRequest) return null;
    return {
      dutyType: affectedDuty.dutyType,
      division: affectedDuty.division,
      submittedStateDateTime: affectedRequest.submittedStateDateTime,
      state: RequestTranslationsUtils.getStatusFields(affectedRequest.state)
    };
  });

  protected readonly EMPTY = EMPTY;
}
