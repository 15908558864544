<msa-request-incomplete-notice />

@if (request().requestType !== RequestType.Reconsideration) {
  <app-duty-context />
} @else {
  <msa-request-context [requestId]="request().id" [dutyId]="request().dutyId" />
}

<form [formGroup]="form" data-cy="detailedExplanationUniversityForm">
  <div class="mt-5">
    <p class="msa-text-field-set tw-mb-6 d:tw-mb-12" safeTranslate="i18n.exam-component.exams-title"></p>
    <p class="msa-text tw-mb-15 d:tw-mb-18" safeTranslate="i18n.exam-component.exams-title-description"></p>

    <!-- Desktop: Header -->
    @if (form.value.schedules?.length ?? 0 > 0) {
      <div
        class="tw-hidden tw-gap-12 tw-border-b tw-border-black-light-4 tw-pb-18 tw-text-black-light-5.5 d:tw-grid d:tw-grid-cols-2"
      >
        <p safeTranslate="i18n.exam-component.exams-date-description"></p>
        <p safeTranslate="i18n.exam-component.fromTo"></p>
      </div>
    }

    @for (dateRangeReason of form.controls.schedules.controls; track dateRangeReason) {
      <div
        class="tw-grid tw-grid-cols-1 tw-justify-start tw-gap-9 tw-border-b tw-border-black-light-4 tw-py-18 d:tw-grid-cols-2 d:tw-flex-row d:tw-gap-30"
        formArrayName="schedules"
      >
        <div class="tw-flex tw-flex-grow tw-flex-col" [formGroupName]="$index">
          <label
            class="tw-mb-6 tw-block d:tw-hidden"
            for="scheduleReason{{ $index }}"
            safeTranslate="i18n.exam-component.exams-date-description"
          ></label>
          <mat-form-field class="tw-w-full" subscriptSizing="dynamic">
            <input
              id="scheduleReason{{ $index }}"
              formControlName="scheduleReason"
              matInput
              placeholder="{{ 'i18n.exam-component.exams-date-hint' | safeTranslate }}"
              autocomplete="off"
              type="text"
            />
          </mat-form-field>
        </div>

        <div>
          <label
            class="tw-mb-6 tw-block d:tw-hidden"
            for="scheduleReason{{ $index }}"
            safeTranslate="i18n.exam-component.fromTo"
          ></label>

          <msa-date-range
            class="tw-flex-grow"
            [formControl]="dateRangeReason.controls.dateRange"
            [id]="$index"
            (remove)="removeSchedule($index)"
            placeholderFrom="{{ 'i18n.exam-component.from' | safeTranslate }}"
            placeholderTo="{{ 'i18n.exam-component.to' | safeTranslate }}"
          />
        </div>
      </div>
    }

    <button
      class="tw-mt-18 !tw-w-fit"
      [disabled]="this.schedulesControl.controls.length >= 4"
      (click)="addSchedule()"
      data-cy="addSchedule"
      type="button"
      mat-flat-button
      color="accent"
    >
      <mat-icon>add</mat-icon>
      {{ "i18n.exam-component.exams-add-appointment" | safeTranslate }}
    </button>
  </div>

  <div class="tw-my-60">
    @if (isOtherUniOrDep(this.request())) {
      <app-msa-content-notice [type]="MessageType.Info">
        <p [innerHTML]="'i18n.duty-relocation-form.university.provide-university-documents-notice' | safeTranslate"></p>
      </app-msa-content-notice>
    }

    <msa-file-upload
      [control]="form.controls.documents"
      [fileRequiredText]="'i18n.duty-relocation-form.documents.file-required'"
      [fileUploadHandler]="requestDocumentUploadService"
      [uploadedDocuments]="documents()"
      [enableDelete]="true"
      (remove)="removeDocument($event)"
      (download)="downloadDocument($event)"
      s3BucketType="PISA"
    />
  </div>

  <div class="tw-mt-30 d:tw-mt-60">
    <label class="input-label" safeTranslate="i18n.duty-relocation-form.examination.comment.title"></label>
    <mat-form-field class="mt-2 dimilar-textarea tw-w-full" appearance="outline">
      <textarea
        #comment
        [attr.data-cy]="'comment'"
        [formControl]="form.controls.reason"
        cdkTextareaAutosize
        matInput
        rows="5"
        maxLength="500"
      ></textarea>
      <mat-hint class="tw-text-end" align="end"> {{ comment.value.length }} / {{ COMMENT_MAX_LENGTH }}</mat-hint>
      <mat-hint align="start" safeTranslate="i18n.duty-relocation-form.examination.comment.description" />
    </mat-form-field>
  </div>
</form>

<div class="tw-mt-30 d:tw-mt-60"></div>

<app-form-stepper-navigation
  [preventNext]="form.invalid || (pendingUploads$ | async) === true"
  [preventBack]="(pendingUploads$ | async) === true"
  (next)="onNext()"
  (previous)="back.emit()"
/>

<msa-save-draft [showSaveDraft]="hasUnsavedChanges()" (saveDraft)="onSaveClick()" />

<app-required-fields-notice />
