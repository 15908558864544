<div class="timepicker-period">
			<button class="timepicker-dial__item timepicker-period__btn"
                  [ngClass]="{'timepicker-dial__item_active': selectedPeriod === timePeriod.AM}"
                  (click)="changePeriod(timePeriod.AM)"
                  type="button">{{meridiems[0]}}</button>
    <button class="timepicker-dial__item timepicker-period__btn"
          [ngClass]="{'timepicker-dial__item_active': selectedPeriod === timePeriod.PM}"
          (click)="changePeriod(timePeriod.PM)"
          type="button">{{meridiems[1]}}</button>
    <div class="timepicker-period__warning" [@scaleInOut] (@scaleInOut.done)="animationDone()" *ngIf="!isPeriodAvailable">
        <p>Current time would be invalid in this period.</p>
    </div>
</div>
