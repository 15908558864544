import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  computed,
  effect,
  inject,
  input,
  signal
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {KeyValueComponent} from '@shared/shared-module/components/key-value/key-value.component';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {TranslateObjectPipe} from '@shared/shared-module/pipes/translate-object.pipe';
import {DetailsShiftGadDto} from 'projects/admin-query/src/app/core/api/generated/msa-admin-query';
import {FetchDivisionSchedule} from 'projects/admin-query/src/app/stores/actions/edit-request.state.actions';
import {
  EditRequestStateSelectors,
  FractionData
} from 'projects/admin-query/src/app/stores/selectors/edit-request-state.selectors';
import {getReplacementTranslation} from 'projects/admin-query/src/app/utils/translation.utils';
import {Subscription, tap} from 'rxjs';

/**
 * This component is only needed to provide the correct list
 * of steps to be displayed.
 */
@Component({
  selector: 'app-gad-substitution-time-summary',
  templateUrl: './gad-substitution-time-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    TranslateObjectPipe,
    MatOptionModule,
    SafeTranslatePipe,
    SafeTranslateDirective,
    KeyValueComponent
  ]
})
export class GadSubstituionTimeSummaryComponent implements OnInit, OnDestroy {
  public requestDetails = input.required<DetailsShiftGadDto>();
  public requestId = input.required<string>();

  private store = inject(Store);
  private fractions = signal<[FractionData | undefined, FractionData | undefined]>([undefined, undefined]);
  fraction1 = computed(() => this.fractions()?.[0]);
  fraction2 = computed(() => this.fractions()?.[1]);
  private subscription: Subscription | undefined;

  constructor() {
    effect(
      () => {
        this.subscription?.unsubscribe();
        this.subscription = this.store
          .select(EditRequestStateSelectors.getFractionPair(this.requestId()))
          .pipe(tap(fractionPair => this.fractions.set(fractionPair)))
          .subscribe();
      },
      {allowSignalWrites: true}
    );
  }

  ngOnInit() {
    this.store.dispatch(new FetchDivisionSchedule());
  }

  readonly replacementText = computed(() => {
    const fraction = this.requestDetails().fraction;
    const value = getReplacementTranslation(fraction);
    const icon = fraction ? 'close' : 'check';
    return {value, icon};
  });

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
