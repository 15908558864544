import {createPropertySelectors, createSelector, Selector} from '@ngxs/store';
import {findById} from '@shared/shared-module/utils/find.utils';
import {GetByIdFn} from '@shared/shared-module/utils/store.utils';
import {DocumentDto, RequestDto} from '../../core/api/generated/msa-admin-query';
import {RequestDutyInfoDto} from '../../core/api/generated/msa-duty-service';
import {RequestsStateModel} from '../models/requests-state.model';
import {RequestsState} from '../states/requests.state';
import {DutyStateSelectors} from './duty.state.selectors';

export class RequestsStateSelectors {
  static slices = createPropertySelectors<RequestsStateModel>(RequestsState);

  @Selector([RequestsStateSelectors.slices.requests])
  static getRequestByIdFn(requests: RequestDto[]): GetByIdFn<RequestDto | null> {
    return (requestId: string) => requests.find(findById(requestId)) ?? null;
  }

  static getRequestById(requestId: string) {
    return createSelector([RequestsStateSelectors.slices.requests], requests => requests.find(findById(requestId)));
  }

  @Selector([RequestsStateSelectors.getRequestByIdFn])
  static getReconsideredRequestByIdFn(getRequestByIdFn: GetByIdFn<RequestDto | null>): GetByIdFn<RequestDto | null> {
    return (reconsiderationId: string) => {
      const reconsiderationRequest = getRequestByIdFn(reconsiderationId);
      if (!reconsiderationRequest?.reconsidered) {
        throw new Error(`Cannot find reconsidered request for reconsideration ${reconsiderationId}!`);
      }
      return getRequestByIdFn(reconsiderationRequest.reconsidered);
    };
  }

  @Selector([RequestsStateSelectors.getRequestByIdFn])
  static getResponsesFn(getRequestByIdFn: GetByIdFn<RequestDto | null>): GetByIdFn<DocumentDto[]> {
    return (requestId: string) => {
      const request = getRequestByIdFn(requestId);
      if (!request) return [];
      return request.responseData.responses;
    };
  }

  @Selector([RequestsStateSelectors.getRequestByIdFn, DutyStateSelectors.getDutyInfoByIdFn])
  static getAffectedDutyFn(
    getRequestByIdFn: GetByIdFn<RequestDto | null>,
    getDutyByIdFn: GetByIdFn<RequestDutyInfoDto | null>
  ) {
    return (requestId: string) => {
      const request = getRequestByIdFn(requestId);
      if (!request) return null;
      return getDutyByIdFn(request.dutyId);
    };
  }
}
