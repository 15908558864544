import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {TranslateModule} from '@ngx-translate/core';
import {FormStepperNavigationComponent} from '@shared/shared-module/components/form-stepper-navigation/form-stepper-navigation.component';
import {MsaFileOverviewComponent} from '@shared/shared-module/components/msa-file-overview/msa-file-overview.component';
import {TranslateObjectPipe} from '@shared/shared-module/pipes/translate-object.pipe';
import {RequestContextComponent} from 'projects/admin-query/src/app/components/request-context/request-context.component';
import {RequestType} from 'projects/admin-query/src/app/core/api/generated/msa-admin-query';
import {DutyContextComponent} from '../../../../components/duty-context/duty-context.component';
import {RequestSummaryComponent} from '../../../request-overview/request-summary/request-summary.component';
import {StepEditRequestComponent} from '../step-edit-request-component/step-edit-request.component';

@Component({
  selector: 'msa-step-request-summary',
  templateUrl: './step-request-summary.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    DutyContextComponent,
    MatButtonModule,
    MatIconModule,
    FormStepperNavigationComponent,
    TranslateObjectPipe,
    MsaFileOverviewComponent,
    RequestSummaryComponent,
    RequestContextComponent
  ]
})
export class StepShiftRequestSummaryComponent extends StepEditRequestComponent {
  protected readonly RequestType = RequestType;

  onNext(): void {
    this.action.emit({type: 'submit'});
  }
}
