import {CommonModule} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  input,
  untracked
} from '@angular/core';
import {takeUntilDestroyed, toSignal} from '@angular/core/rxjs-interop';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators, ɵValue} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {DateRange, MatDatepicker} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {MsaLocalizedDatePickerModule} from '@shared/shared-module/adapters/msa-localized-date-picker.module';
import {FormStepperNavigationComponent} from '@shared/shared-module/components/form-stepper-navigation/form-stepper-navigation.component';
import {MsaCheckboxComponent} from '@shared/shared-module/components/msa-checkbox/msa-checkbox.component';
import {MsaSaveDraftComponent} from '@shared/shared-module/components/msa-save-draft/msa-save-draft.component';
import {RequiredFieldsNoticeComponent} from '@shared/shared-module/components/required-fields-notice/required-fields-notice.component';
import {DEFAULT_DEBOUNCE_TIME_MS} from '@shared/shared-module/config/rxjs.config';
import {MarkRequiredDirective} from '@shared/shared-module/directives/mark-required.directive';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {translateObject, TranslateObjectPipe} from '@shared/shared-module/pipes/translate-object.pipe';
import {getOtherUniversity, isOtherUniversity} from '@shared/shared-module/utils/code-utils';
import {ENGLISH_SHORT_DATE_FORMAT, formatDate} from '@shared/shared-module/utils/date-time.utils';
import {findByCodeV2} from '@shared/shared-module/utils/find.utils';
import {MsaValidators} from '@shared/shared-module/utils/validator.utils';
import moment, {Moment} from 'moment';
import {CodeDtoV2} from 'projects/admin-query/src/app/core/api/generated/msa-duty-service';
import {debounceTime, map, tap} from 'rxjs';
import {DutyContextComponent} from '../../../../components/duty-context/duty-context.component';
import {RequestContextComponent} from '../../../../components/request-context/request-context.component';
import {
  CodeDto,
  DaysOfWeekDto,
  DetailsShiftFdtUniversityDto,
  ImmutableConstraint,
  RequestType,
  UpdateUniversityDto
} from '../../../../core/api/generated/msa-admin-query';
import {
  FetchDepartmentCodesForUniversity,
  FetchUniversityNameCodeList
} from '../../../../stores/actions/code-list.state.actions';
import {CodeListStateSelectors} from '../../../../stores/selectors/code-list.state.selectors';
import {MsaDateRangeComponent} from '../../general/date-range/date-range.component';
import {RequestIncompleteNoticeComponent} from '../../general/request-incomplete-notice/request-incomplete-notice.component';
import {StepEditRequestComponent} from '../../general/step-edit-request-component/step-edit-request.component';

interface UniversityForm {
  university: FormControl<string>;
  universityDepartment: FormControl<CodeDto['code'] | null>;
  studyType: FormControl<string>;
  studyPartTime: FormControl<boolean>;
  studyDays?: FormGroup<StudyDaysFormGroup>;
  semesterDuration: FormControl<DateRange<Date> | undefined>;
  studiesEnd: FormControl<Date | undefined>;
}

interface StudyDaysFormGroup {
  monday: FormControl<boolean>;
  tuesday: FormControl<boolean>;
  wednesday: FormControl<boolean>;
  thursday: FormControl<boolean>;
  friday: FormControl<boolean>;
  saturday: FormControl<boolean>;
  sunday: FormControl<boolean>;
  irregular: FormControl<boolean>;
}

enum StudyType {
  HOEHERE_BERUFSBILDUNG = '01',
  HOCHSCHULE = '02',
  UNIVERSITAET = '03'
}

@Component({
  selector: 'msa-step-university',
  templateUrl: './step-university.component.html',
  standalone: true,
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatButtonModule,
    MatFormFieldModule,
    FormStepperNavigationComponent,
    DutyContextComponent,
    MarkRequiredDirective,
    RequiredFieldsNoticeComponent,
    TranslateObjectPipe,
    MsaSaveDraftComponent,
    RequestIncompleteNoticeComponent,
    RequestContextComponent,
    MsaDateRangeComponent,
    MsaLocalizedDatePickerModule,
    MatIconModule,
    MatAutocompleteModule,
    SafeTranslatePipe,
    SafeTranslateDirective,
    MsaCheckboxComponent
  ]
})
export class StepUniversityComponent extends StepEditRequestComponent implements AfterViewInit {
  public universities = input<CodeDtoV2[]>([]);
  public universityDepartments = input<CodeDtoV2[]>([]);

  public translateService = inject(TranslateService);
  private destroyRef = inject(DestroyRef);
  private cdr = inject(ChangeDetectorRef);

  form = new FormGroup<UniversityForm>({
    university: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    universityDepartment: new FormControl(null, {nonNullable: false, validators: []}),
    studyType: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    studyPartTime: new FormControl(false, {nonNullable: true, validators: [Validators.required]}),
    semesterDuration: new FormControl(undefined, {nonNullable: true, validators: [Validators.required]}),
    studiesEnd: new FormControl(undefined, {
      nonNullable: true,
      validators: [Validators.required, MsaValidators.isInTheFuture]
    })
  });

  protected readonly RequestType = RequestType;
  protected readonly StudyType = StudyType;

  private isReadonly = computed(() => this.request().immutables.some(i => i === ImmutableConstraint.University));
  public selectedUniversityCode = toSignal(this.form.controls.university.valueChanges, {initialValue: null});
  private currentLang = toSignal(this.translateService.onLangChange.pipe(map(change => change.lang)), {
    initialValue: this.translateService.currentLang
  });

  filteredUniversityNames = computed(() => {
    const query = this.selectedUniversityCode();
    return CodeListStateSelectors.filterUniversitiesByName(this.universities(), query ?? '', this.currentLang());
  });

  constructor() {
    super();

    this.form.valueChanges
      .pipe(
        debounceTime(DEFAULT_DEBOUNCE_TIME_MS),
        tap(() => {
          this.action.emit({
            type: 'updateUserFormData',
            payload: {
              university: {
                university: this.form.value.university,
                universityDepartment: this.form.value.universityDepartment ?? undefined,
                studyType: this.form.value.studyType,
                studyPartTime: this.form.value.studyPartTime ?? false,
                studyDays: this.getUpdatedStudyDays(this.form.value.studyDays),
                semesterDuration: this.getSemesterDuration(this.form.value.semesterDuration),
                studiesEnd: this.form.value.studiesEnd
                  ? formatDate(this.form.value.studiesEnd, ENGLISH_SHORT_DATE_FORMAT)
                  : undefined
              }
            }
          });
        }),
        takeUntilDestroyed()
      )
      .subscribe();

    effect(() => {
      const selectedUniversityCode = this.selectedUniversityCode();
      if (!selectedUniversityCode) return;
      this.fetchResource.emit(new FetchDepartmentCodesForUniversity(selectedUniversityCode));
    });

    effect(() => {
      const isReadonly = this.isReadonly();
      const departments = this.universityDepartments();

      untracked(() => {
        this.form.enable();
        if (isReadonly) {
          this.form.disable();
        }

        // disable departments if none there
        this.form.controls.universityDepartment.removeValidators(Validators.required);
        this.form.controls.universityDepartment.disable();
        if (departments.length > 0) {
          if (!isReadonly) {
            this.form.controls.universityDepartment.enable();
          }
          this.form.controls.universityDepartment.setValidators([Validators.required]);
        }
        this.form.controls.universityDepartment.updateValueAndValidity();
      });
    });
  }

  ngAfterViewInit(): void {
    this.loadForm();
    this.onPartTimeChange();
  }

  displayFn = (universityHash: string): string => {
    let university: CodeDtoV2 = this.universities().find(findByCodeV2(universityHash))!;
    if (isOtherUniversity(universityHash)) {
      university = getOtherUniversity();
    }

    const universityName = university
      ? translateObject(university.descriptionDto, this.translateService.currentLang)
      : '';

    if (!universityName) return '';
    // name might be translation key
    return this.translateService.instant(universityName);
  };

  onNext(): void {
    this.action.emit({type: 'updateUniversity', payload: this.updatedUniversity()});
  }

  onSaveClick(): void {
    this.action.emit({type: 'saveDraft'});
  }

  setStudyEnd(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>): void {
    const studyEnd = this.form.controls.studiesEnd.value ?? new Date();
    studyEnd.setMonth(moment(normalizedMonthAndYear).month());
    studyEnd.setFullYear(moment(normalizedMonthAndYear).year());
    studyEnd.setDate(28);
    this.form.controls.studiesEnd.setValue(studyEnd);
    datepicker.close();
  }

  private updatedUniversity(): UpdateUniversityDto {
    const rawValues = this.form.getRawValue();
    return {
      university: rawValues.university ?? '',
      universityDepartment: rawValues.universityDepartment ?? '',
      studyType: rawValues.studyType ?? '',
      studyPartTime: rawValues.studyPartTime ?? false,
      studyDays: this.getUpdatedStudyDays(rawValues.studyDays) ?? [],
      semesterDuration: this.getSemesterDuration(rawValues.semesterDuration) ?? {from: null, to: null},
      studiesEnd: rawValues.studiesEnd ? formatDate(rawValues.studiesEnd, ENGLISH_SHORT_DATE_FORMAT) : ''
    };
  }

  private loadForm(): void {
    // in order to resolve university, we need to wait until they are loaded
    this.fetchResource.emit(new FetchUniversityNameCodeList());

    const universityDetails = (this.request().requestDetail as DetailsShiftFdtUniversityDto).university;

    this.form.patchValue(
      {
        university: universityDetails.university,
        universityDepartment: universityDetails.universityDepartment,
        studyType: universityDetails.studyType,
        studyPartTime: universityDetails.studyPartTime,
        semesterDuration:
          universityDetails.semesterDuration?.from && universityDetails.semesterDuration?.to
            ? new DateRange(
                new Date(universityDetails.semesterDuration.from),
                new Date(universityDetails.semesterDuration.to)
              )
            : undefined,
        studiesEnd: universityDetails.studiesEnd ? new Date(universityDetails.studiesEnd) : undefined
      },
      {emitEvent: false}
    );
  }

  clearField(): void {
    this.form.controls.university.setValue('');

    this.form.controls.universityDepartment.setValue(null);
    this.form.controls.universityDepartment.disable();
    this.form.controls.universityDepartment.setValidators([]);

    this.form.controls.university.updateValueAndValidity({onlySelf: true});
    this.form.controls.universityDepartment.updateValueAndValidity({onlySelf: true});
    this.form.controls.studyDays?.updateValueAndValidity({onlySelf: true});
  }

  private onPartTimeChange(): void {
    const universityDetails = (this.request().requestDetail as DetailsShiftFdtUniversityDto).university;
    this.form.controls.studyPartTime.valueChanges
      .pipe(
        tap(isPartTime => {
          if (!isPartTime) {
            this.updateStudyDaysCheckboxValues(undefined);
            this.form.removeControl('studyDays');
          } else {
            this.form.addControl(
              'studyDays',
              new FormGroup(
                {
                  monday: new FormControl<boolean>(false, {nonNullable: true, validators: []}),
                  tuesday: new FormControl<boolean>(false, {nonNullable: true, validators: []}),
                  wednesday: new FormControl<boolean>(false, {nonNullable: true, validators: []}),
                  thursday: new FormControl<boolean>(false, {nonNullable: true, validators: []}),
                  friday: new FormControl<boolean>(false, {nonNullable: true, validators: []}),
                  saturday: new FormControl<boolean>(false, {nonNullable: true, validators: []}),
                  sunday: new FormControl<boolean>(false, {nonNullable: true, validators: []}),
                  irregular: new FormControl<boolean>(false, {nonNullable: true, validators: []})
                },
                {validators: [Validators.required, MsaValidators.atLeastOneSelectedValidator]}
              )
            );
            this.updateStudyDaysCheckboxValues(universityDetails.studyDays);
            this.form.controls.studyDays!.updateValueAndValidity();
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private getSemesterDuration(semesterDuration: DateRange<Date> | undefined) {
    if (!semesterDuration) return undefined;
    return {
      from: semesterDuration.start ? formatDate(semesterDuration.start, ENGLISH_SHORT_DATE_FORMAT) : null,
      to: semesterDuration.end ? formatDate(semesterDuration.end, ENGLISH_SHORT_DATE_FORMAT) : null
    };
  }

  private getUpdatedStudyDays(studyDays: ɵValue<FormGroup<StudyDaysFormGroup>> | undefined): DaysOfWeekDto[] {
    if (!studyDays) {
      return [];
    }
    const studyDaysValues: {[days in DaysOfWeekDto]: boolean} = {
      [DaysOfWeekDto.Monday]: studyDays?.monday ?? false,
      [DaysOfWeekDto.Tuesday]: studyDays?.tuesday ?? false,
      [DaysOfWeekDto.Wednesday]: studyDays?.wednesday ?? false,
      [DaysOfWeekDto.Thursday]: studyDays?.thursday ?? false,
      [DaysOfWeekDto.Friday]: studyDays?.friday ?? false,
      [DaysOfWeekDto.Saturday]: studyDays?.saturday ?? false,
      [DaysOfWeekDto.Sunday]: studyDays?.sunday ?? false,
      [DaysOfWeekDto.Irregular]: studyDays?.irregular ?? false
    };
    const daysList = Object.entries(studyDaysValues)
      .filter(([, value]) => value)
      .map(([days]) => days as DaysOfWeekDto);
    return daysList.length === 0 ? [] : daysList;
  }

  private updateStudyDaysCheckboxValues(days: DaysOfWeekDto[] | undefined): void {
    const studyDays = this.form.controls.studyDays;
    if (!days || !studyDays) {
      return;
    }
    studyDays.controls.monday.patchValue(days.includes(DaysOfWeekDto.Monday));
    studyDays.controls.tuesday.patchValue(days.includes(DaysOfWeekDto.Tuesday));
    studyDays.controls.wednesday.patchValue(days.includes(DaysOfWeekDto.Wednesday));
    studyDays.controls.thursday.patchValue(days.includes(DaysOfWeekDto.Thursday));
    studyDays.controls.friday.patchValue(days.includes(DaysOfWeekDto.Friday));
    studyDays.controls.saturday.patchValue(days.includes(DaysOfWeekDto.Saturday));
    studyDays.controls.sunday.patchValue(days.includes(DaysOfWeekDto.Sunday));
    studyDays.controls.irregular.patchValue(days.includes(DaysOfWeekDto.Irregular));
  }
}
