<!--suppress HtmlFormInputWithoutLabel, HtmlUnknownAttribute -->
<input class="timepicker-dial__control timepicker-dial__item"
       [ngClass]="{'timepicker-dial__item_active': isActive}"
       [ngModel]="time | timeLocalizer: timeUnit"
       (ngModelChange)="time = $event"
       [disabled]="disabled"
       (input)="updateTime()" (focus)="saveTimeAndChangeTimeUnit($event, timeUnit)"
       readonly [timepickerAutofocus]="isActive"
       *ngIf="!isEditable;else editableTemplate">

<ng-template #editableTemplate>
    <!--suppress HtmlFormInputWithoutLabel, HtmlUnknownAttribute -->
    <input class="timepicker-dial__control timepicker-dial__item timepicker-dial__control_editable"
           #editableTimeTmpl
           [formControl]="timeControl"
           [ngClass]="{'timepicker-dial__item_active': isActive}"
           [timepickerAutofocus]="isActive"
           (focus)="saveTimeAndChangeTimeUnit($event, timeUnit)"
           (keydown)="onKeydown($event)">
</ng-template>
