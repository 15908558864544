import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, effect, inject, input, OnInit} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {MessageType} from '@shared/shared-module/components/enums/messageType';
import {FormStepperNavigationComponent} from '@shared/shared-module/components/form-stepper-navigation/form-stepper-navigation.component';
import {MsaContentNoticeComponent} from '@shared/shared-module/components/msa-content-notice/msa-content-notice.component';
import {MsaSaveDraftComponent} from '@shared/shared-module/components/msa-save-draft/msa-save-draft.component';
import {MsaTooltipComponent} from '@shared/shared-module/components/msa-tooltip/msa-tooltip.component';
import {RequiredFieldsNoticeComponent} from '@shared/shared-module/components/required-fields-notice/required-fields-notice.component';
import {MarkRequiredDirective} from '@shared/shared-module/directives/mark-required.directive';
import {SafeTranslateDirective} from '@shared/shared-module/directives/safe-translate.directive';
import {SafeTranslatePipe} from '@shared/shared-module/pipes/safe-translate.pipe';
import {PersonDataStateSelectors} from '@shared/shared-module/stores/selectors/person-data.state.selectors';
import {
  addToDate,
  ENGLISH_SHORT_DATE_FORMAT,
  GERMAN_SHORT_DATE_FORMAT
} from '@shared/shared-module/utils/date-time.utils';
import {getSeasonTranslation} from '@shared/shared-module/utils/season-utils';
import moment from 'moment';
import {DutyContextComponent} from 'projects/admin-query/src/app/components/duty-context/duty-context.component';
import {RequestContextComponent} from 'projects/admin-query/src/app/components/request-context/request-context.component';
import {
  DetailsShiftGadDto,
  DivisionScheduleDto,
  DutyReplacementDto,
  FixedCodeHashes,
  RequestType,
  Season
} from 'projects/admin-query/src/app/core/api/generated/msa-admin-query';
import {RequestDutyInfoDto} from 'projects/admin-query/src/app/core/api/generated/msa-duty-service';
import {MsaRadiobuttonComponent} from '../../../../../../../../../src/app/shared-module/components/msa-radiobutton/msa-radiobutton.component';
import {FetchDivisionSchedule} from '../../../../../stores/actions/edit-request.state.actions';
import {EditRequestStateSelectors} from '../../../../../stores/selectors/edit-request-state.selectors';
import {RequestIncompleteNoticeComponent} from '../../../general/request-incomplete-notice/request-incomplete-notice.component';
import {StepEditRequestComponent} from '../../../general/step-edit-request-component/step-edit-request.component';

@Component({
  selector: 'msa-step-gad-substitution-period',
  templateUrl: './step-gad-displacement-time.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {subscriptSizing: 'dynamic'}}],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    FormStepperNavigationComponent,
    DutyContextComponent,
    MarkRequiredDirective,
    RequiredFieldsNoticeComponent,
    RequestIncompleteNoticeComponent,
    MatButtonModule,
    MatIconModule,
    MsaContentNoticeComponent,
    MsaSaveDraftComponent,
    RequestContextComponent,
    SafeTranslatePipe,
    SafeTranslateDirective,
    MsaTooltipComponent,
    MatOptionModule,
    MatSelectModule,
    MsaRadiobuttonComponent
  ]
})
export class StepGadDisplacementTimeComponent extends StepEditRequestComponent implements OnInit {
  private store: Store = inject(Store);
  private translateService = inject(TranslateService);

  protected readonly GERMAN_SHORT_DATE_FORMAT = GERMAN_SHORT_DATE_FORMAT;
  protected readonly getSeasonTranslation = getSeasonTranslation;
  protected readonly RequestType = RequestType;
  protected readonly message = MessageType;

  weeksTillFraction: number[] = [9, 10, 11, 12];

  form = new FormGroup<GadDisplacementForm>({
    atOnce: new FormControl(false, {nonNullable: true, validators: [Validators.required]}),
    period1: new FormControl<DivisionScheduleDto>({}, Validators.required),
    period2: new FormControl<DivisionScheduleDto>({}, this.period2Validator()),
    weeksTillFraction: new FormControl<number>(0)
  });

  duty = input.required<RequestDutyInfoDto>();
  requestDetail = computed<DetailsShiftGadDto>(() => this.request().requestDetail as DetailsShiftGadDto);
  personalData = toSignal(this.store.select(PersonDataStateSelectors.getPersonalData));
  birthdate = computed(() => {
    return this.personalData()?.birthdate;
  });
  formValues = toSignal(this.form.valueChanges, {initialValue: this.form.value});
  languageChanges = toSignal(this.translateService.onLangChange, {
    initialValue: {lang: this.translateService.currentLang, translations: {}}
  });
  divisionSchedules = toSignal(this.store.select(EditRequestStateSelectors.slices.divisionSchedules), {
    initialValue: []
  });

  // calculation remaining weeks fraction pt. II
  weeksFractionTillEnd = computed(() => {
    this.languageChanges(); // added this signal to computed to listen to changes in language and then trigger a new signal
    if (this.formValues().period2 && this.formValues().weeksTillFraction) {
      const week = this.translateService.instant('i18n.common.weeks');
      const fractionStart = String(this.formValues().weeksTillFraction! + 1);
      const dateTo = String(this.formValues()?.period1?.weeks);
      return ''.concat(week, ' ', fractionStart, ' - ', dateTo);
    }
    return '';
  });

  // calculate fraction I end date
  season1CalculatedEndDate = computed(() => {
    if (!this.formValues().atOnce) {
      return addToDate(
        <string>this.formValues().period1?.endDate,
        <number>this.formValues().weeksTillFraction - <number>this.formValues().period1?.weeks,
        1
      );
    }
    return '';
  });

  // calculate fraction II start date
  season2CalculatedStartDate = computed(() => {
    if (this.formValues().period2?.startDate) {
      return addToDate(<string>this.formValues().period2?.startDate, <number>this.formValues().weeksTillFraction, 0);
    }
    return '';
  });

  period1 = computed(() => {
    return (
      this.divisionSchedules().find(
        s => s.id === this.requestDetail().replacementYear1 + '-' + this.requestDetail().replacementSeason1
      ) || this.divisionSchedules()[0]
    );
  });

  period2 = computed(() => {
    const schedule2 = this.divisionSchedules().find(
      s => s.id === this.requestDetail().replacementYear2 + '-' + this.requestDetail().replacementSeason2
    );
    if (schedule2) {
      return schedule2;
    } else if (this.requestDetail().fraction) {
      return this.divisionSchedules().at(this.divisionSchedules().indexOf(this.period1()) + 1);
    } else {
      return {};
    }
  });

  constructor() {
    super();

    effect(
      () => {
        this.form.patchValue({
          period1: this.period1(),
          period2: this.period2(),
          atOnce: !this.requestDetail().fraction
        });
      },
      {allowSignalWrites: true}
    );

    effect(
      () => {
        const formValues = this.formValues();

        this.action.emit({
          type: 'updateUserFormData',
          payload: {
            fraction: !formValues.atOnce,
            weeksTillFraction: formValues.weeksTillFraction!,
            replacementSeason1: formValues.period1?.season,
            replacementSeason2: formValues.period2?.season,
            replacementYear1: formValues.period1?.year,
            replacementYear2: formValues.period2?.year
          }
        });
      },
      {allowSignalWrites: true}
    );
    this.form.controls.atOnce.valueChanges.subscribe(atOnce => {
      if (atOnce) {
        this.form.controls.period2.reset({});
        this.form.controls.weeksTillFraction.reset(0);
      } else {
        this.form.controls.period2.setValue(this.period2()?.year ? this.period2()! : this.getPeriod2DateRange()[0]);
        this.form.controls.weeksTillFraction.setValue(
          this.requestDetail().weeksTillFraction == 0 ? 12 : this.requestDetail().weeksTillFraction
        );
      }
    });
  }

  ngOnInit() {
    this.store.dispatch(new FetchDivisionSchedule());
  }

  onSeason1Selected() {
    this.form.controls.period2.setValue(this.getPeriod2DateRange()[0]);
  }

  isDutyTypeDurchdiener(): boolean {
    // Codehash Duty Type 'J' - Durchdiener
    return this.duty().dutyTypeCodeHash === FixedCodeHashes.DutyTypeJ;
  }

  isOlderThan(ageLimit: number) {
    const dateLimit = moment(this.birthdate(), ENGLISH_SHORT_DATE_FORMAT).add(ageLimit, 'years');
    return dateLimit.isBefore(moment.now());
  }

  period2Validator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isAtOnce = control.parent?.get('atOnce')?.value;
      const period2 = control.parent?.get('period2')?.value;
      if (!isAtOnce && !period2) {
        return {fractionHasNoPeriod2: true};
      }
      return null;
    };
  }

  getDateLatest() {
    const year: number = Number(this.duty().startDate.slice(0, 4));
    return moment().year(year).month(11).date(31).add(1, 'years');
  }

  /**
   * Non-fraction
   */
  getDateRange(): DivisionScheduleDto[] {
    return this.divisionSchedules()
      .slice()
      .filter(
        s =>
          moment(<string>s.startDate, ENGLISH_SHORT_DATE_FORMAT).isBefore(this.getDateLatest()) &&
          !moment(<string>s.startDate, ENGLISH_SHORT_DATE_FORMAT).isSame(this.duty().startDate)
      );
  }

  /**
   * Fraction - Part I
   */
  getPeriod1DateRange(): DivisionScheduleDto[] {
    const divisionSchedule = this.divisionSchedules()
      .slice()
      .filter(s => moment(<string>s.startDate, ENGLISH_SHORT_DATE_FORMAT).isBefore(this.getDateLatest()));
    return divisionSchedule;
  }

  /**
   * Fraction - Part II
   */
  getPeriod2DateRange(): DivisionScheduleDto[] {
    if (!this.form.value.period1) {
      return [];
    }
    const season1StartDate: Date = new Date(<string>this.form.value.period1.startDate);
    const season2MaxStartDate: Date = new Date(season1StartDate.getFullYear() + 1, 11, 31); // month index: 0 - 11
    return this.divisionSchedules()
      .slice()
      .filter(s => new Date(<string>s.startDate) > season1StartDate)
      .filter(s => new Date(<string>s.startDate) < season2MaxStartDate);
  }

  updateDto(): DutyReplacementDto {
    return {
      fraction: !this.form.value.atOnce,
      weeksTillFraction: this.form.value.weeksTillFraction as number,
      replacementYear1: this.form.value.period1?.year as number,
      replacementSeason1: this.form.value.period1?.season as Season,
      replacementYear2: this.form.value.period2?.year as number,
      replacementSeason2: this.form.value.period2?.season as Season
    };
  }

  onSaveClick(): void {
    this.action.emit({type: 'saveDraft'});
  }

  onNext(): void {
    this.action.emit({type: 'updateDutyReplacementGad', payload: this.updateDto()});
  }
}

interface GadDisplacementForm {
  atOnce: FormControl<boolean | null>;
  period1: FormControl<DivisionScheduleDto | null>;
  period2: FormControl<DivisionScheduleDto | null>;
  weeksTillFraction: FormControl<number | null>;
}
