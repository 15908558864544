<msa-request-incomplete-notice />

@if (request().requestType !== RequestType.Reconsideration) {
  <app-duty-context />
}

@if (request().requestType === RequestType.Reconsideration) {
  <msa-request-context [requestId]="request().id" [dutyId]="request().dutyId" />
}

<form [formGroup]="form" data-cy="detailedExplanationForm">
  <div class="tw-mt-30 d:tw-mt-60">
    <label
      class="msa-text"
      [appMarkRequired]="form.controls.detailedExplanation"
      safeTranslate="i18n.duty-relocation-form.motivation.description"
    ></label>
    <mat-form-field class="tw-w-full" appearance="outline">
      <textarea
        #detailedExplanation
        [formControl]="form.controls.detailedExplanation"
        data-cy="reason"
        cdkTextareaAutosize
        matInput
        rows="5"
        maxLength="500"
        minlength="5"
      ></textarea>
      <mat-hint class="msa-text-small tw-text-end tw-text-black-light-5" align="end">
        {{ detailedExplanation.value.length }} / {{ 500 }}
      </mat-hint>
      <mat-hint class="msa-text-small tw-text-black-light-5" [safeTranslate]="reasonHintText()" align="start" />
    </mat-form-field>
  </div>

  <div class="tw-mt-30 d:tw-mt-60"></div>

  <msa-file-upload
    [control]="form.controls.uploadDocument"
    [fileRequiredText]="'i18n.duty-relocation-form.documents.file-required'"
    [fileUploadHandler]="requestDocumentUploadService"
    [uploadedDocuments]="documents()"
    [enableDelete]="true"
    [s3BucketType]="s3BucketType()"
    (remove)="removeDocument($event)"
    (download)="downloadDocument($event)"
  />
</form>

<div class="tw-mt-30 d:tw-mt-60"></div>

<app-form-stepper-navigation
  [preventNext]="form.invalid || (pendingUploads$ | async) === true"
  [preventBack]="(pendingUploads$ | async) === true"
  (next)="onNext()"
  (previous)="back.emit()"
/>

<msa-save-draft [showSaveDraft]="hasUnsavedChanges()" (saveDraft)="onSaveClick()" />

<app-required-fields-notice />
