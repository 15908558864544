import {FixedCodeHashes} from 'projects/admin-query/src/app/core/api/generated/msa-admin-query';
import {CodeDtoV2} from 'projects/admin-query/src/app/core/api/generated/msa-duty-service';

export function isOtherUniversity(code: string | undefined): boolean {
  return code === FixedCodeHashes.OtherUniversity;
}

export function getOtherDepartment(): CodeDtoV2 {
  return {
    codeHash: FixedCodeHashes.OtherDepartment,
    descriptionDto: {
      de: 'i18n.duty-relocation-form.university.other-department',
      fr: 'i18n.duty-relocation-form.university.other-department',
      it: 'i18n.duty-relocation-form.university.other-department'
    }
  };
}

export function getOtherUniversity(): CodeDtoV2 {
  return {
    codeHash: FixedCodeHashes.OtherUniversity,
    descriptionDto: {
      de: 'i18n.duty-relocation-form.university.other-university',
      fr: 'i18n.duty-relocation-form.university.other-university',
      it: 'i18n.duty-relocation-form.university.other-university'
    }
  };
}
